import { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useBingoContext } from '../../components/bingo/BingoContext.jsx';
import useAuth from '../../utilities/AuthContext.jsx';
import CallApi from '../../utilities/ApiCallers.jsx';


const ROUTE = '/api/bingo/save-card';
const METHOD = 'POST';


const Selection = ({status, disable}) => {
  const state = useBingoContext();
  const { bingoCard, noBingo, setNoBingo } = state;
  const [modalSubmitShow, setModalSubmitShow] = useState(false);
  const handleModalSubmitClose = () => setModalSubmitShow(false);
  const handleModalSubmitShow = () => setModalSubmitShow(true);
  const auth = useAuth();
  const user = auth.UserObj ? auth.UserObj : false;
  const [submit, setSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitFailure, setSubmitFailure] = useState(false);

  const saveCard = async () => {
    const cardInfo = {
      "card": bingoCard,
      "userId": user.id
    }
    try {
      setModalSubmitShow(false);
      setSubmit(true);
      const response = await CallApi(ROUTE, METHOD, cardInfo, user.token);
      if (response.ok) {
        setSubmitSuccess(true);
        setNoBingo(() => true);
        setSubmit(false);
      };
    } catch(err) {
      setSubmitFailure(true);
      setSubmit(false);
    };
  };

  return (
    <>
      <Button
        className='mb-2 LibraryBtn'
        onClick={() => handleModalSubmitShow()}
        variant='outline-light'
        disabled={noBingo}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" className="bi bi-save" viewBox="0 0 16 16">
          <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
        </svg>
        &nbsp;
        Select
      </Button>
      <Modal
        show={modalSubmitShow}
        onHide={handleModalSubmitClose}
        aria-labelledby='bingo selection'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            Are you sure? You will be locked into this card until it is finished.
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="danger" onClick={handleModalSubmitClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={saveCard}>
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={submit}
        animation={false}
        aria-labelledby='loading'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Spinner animation="border" />
          </Modal.Title>
        </Modal.Header>
      </Modal>
      <Modal
        show={submitSuccess}
        onHide={() => setSubmitSuccess(false)}
        aria-labelledby='success'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            Success! Have fun!
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant='success' onClick={() => setSubmitSuccess(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={submitFailure}
        onHide={() => setSubmitFailure(false)}
        aria-labelledby='failure'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            Something went wrong! If it continues, reach out to a mod.
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant='danger' onClick={() => setSubmitFailure(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Selection;
