import Koga from '../../assets/sprites/Koga.png';
import style from './Koga.module.css';
import Walking from '../walking.jsx';

const KogaWalking = ({top, left}) => {
  return (
    <Walking
      container={style.walkingContainer}
      character='Koga'
      sheet={Koga}
      top={top}
      left={left}
      frames={4}
    />
  )
}

export default KogaWalking;
