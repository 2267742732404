import Yura from '../../assets/sprites/Yura.png';
import style from './Yura.module.css';
import Walking from '../walking.jsx';

const YuraWalking = ({top, left}) => {
  return (
    <Walking
      container={style.walkingContainer}
      character='Yura'
      sheet={Yura}
      top={top}
      left={left}
      frames={4}
    />
  )
}

export default YuraWalking;
