import Kanna from '../../assets/sprites/Kanna.png';
import style from './Kanna.module.css';
import Walking from '../walking.jsx';

const KannaWalking = ({top, left}) => {
  return (
    <Walking
      container={style.walkingContainer}
      character='Kanna'
      sheet={Kanna}
      top={top}
      left={left}
      frames={4}
    />
  )
}

export default KannaWalking;
