import { Row, Col, Container } from 'react-bootstrap';
import KagomeWalking from './kagome/KagomeWalking.jsx';
import InuyashaWalking from './inuyasha/InuyashaWalking.jsx';
import SangoWalking from './sango/SangoWalking.jsx';
import MirokuWalking from './miroku/MirokuWalking.jsx';
import ShippoWalking from './shippo/ShippoWalking.jsx';
import KaguraWalking from './kagura/KaguraWalking.jsx';
import KikyoWalking from './kikyo/KikyoWalking.jsx';
import KogaWalking from './koga/KogaWalking.jsx';
import SesshomaruWalking from './sesshomaru/SesshomaruWalking.jsx';
import JakenWalking from './jaken/JakenWalking.jsx';
import NarakuWalking from './naraku/NarakuWalking.jsx';
import YuraWalking from './yura/YuraWalking.jsx';
import KannaWalking from './kanna/KannaWalking.jsx';
import UrasueWalking from './urasue/UrasueWalking.jsx';
import KohakuWalking from './kohaku/KohakuWalking.jsx';

const LoadingAnimation = () => {
  return (
    <Container>
      <Row style={{marginBottom: '25vh', marginTop: '10vh'}}>
      <Col className='d-flex justify-content-center'>
          <MirokuWalking />
        </Col>
        <Col className='d-flex justify-content-center'>
          <SangoWalking />
        </Col>
        <Col className='d-flex justify-content-center'>
          <ShippoWalking />
        </Col>
        <Col className='d-flex justify-content-center'>
          <KagomeWalking />
        </Col>
        <Col className='d-flex justify-content-center'>
          <InuyashaWalking />
        </Col>
      </Row>
      <Row style={{marginBottom: '25vh'}}>
      <Col className='d-flex justify-content-center'>
          <KogaWalking />
        </Col>
        <Col className='d-flex justify-content-center'>
          <KikyoWalking />
        </Col>
        <Col className='d-flex justify-content-center'>
          <JakenWalking />
        </Col>
        <Col className='d-flex justify-content-center'>
          <KaguraWalking />
        </Col>
        <Col className='d-flex justify-content-center'>
          <SesshomaruWalking />
        </Col>
      </Row>
      <Row>
      <Col className='d-flex justify-content-center'>
          <KohakuWalking />
        </Col>
        <Col className='d-flex justify-content-center'>
          <UrasueWalking />
        </Col>
        <Col className='d-flex justify-content-center'>
          <KannaWalking />
        </Col>
        <Col className='d-flex justify-content-center'>
          <YuraWalking />
        </Col>
        <Col className='d-flex justify-content-center'>
          <NarakuWalking />
        </Col>
      </Row>
    </Container>
  );
};

export default LoadingAnimation;
