import Naraku from '../../assets/sprites/Naraku.png';
import style from './Naraku.module.css';
import Walking from '../walking.jsx';

const NarakuWalking = ({top, left}) => {
  return (
    <Walking
      container={style.walkingContainer}
      character='Naraku'
      sheet={Naraku}
      top={top}
      left={left}
      frames={4}
    />
  )
}

export default NarakuWalking;
