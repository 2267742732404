import { Container } from 'react-bootstrap';
import BingoTab from './PublicBingoTab.jsx'
import { BingoContextProvider } from '../../components/bingo/BingoContext.jsx';


const NAMELIST = [
  "Cinderella", "The Little Mermaid", "Aladdin", "Rapunzel / Tangled",
  "Mulan", "Little Red Ridinghood", "Sleeping Beauty", "Beauty and the Beast",
  "Snow White", "Atlantis: the Lost Empire", "Shrek", "Frozen", "Hercules",
  "Sword in the Stone", "Hansel and Gretel", "Princess and the Frog", "Dumbo",
  "Pinocchio", "Alice in Wonderland", "Peter Pan", "Lady and the Tramp",
  "The Fox and the Hound", "The Lion King", "The Hunchback of Notre Dame",
  "Tarzan", "Brave", "Moana", "Anastasia", "Aristocrats", "The Swan Princess",
  "Jack and the Beanstalk", "Rumpelstiltskin", "Thumbelina", "The Golden Goose",
  "Sinbad", "Goldilocks", "The Ugly Duckling", "Wizard of Oz", "The Black Cauldron",
  "The Little Match Girl", "Robin Hood", "The Emperor's New Groove" ,"FernGully",
  "Quest for Camelot", "Enchanted", "Howl's Moving Castle", "The Princess Bride",
  "The Jungle Book", "Treasure Planet", "Prince Charming", "Damsel", "Tower", "Enchantment",
  "Crown/Tiara", "Kiss", "Villain", "Beast", "Wicked", "Forest", "Carriage",
  "Curse", "Wand", "Spell", "Legend", "Quest", "Queen", "Sorcerer/Witch", "Wish",
  "Glass", "Castle", "Princess", "Once Upon a Time", "Happily Ever After",
  "Throne", "Treasure", "True Love"
];
const TWOVALUES = false;


const PublicBingo = () => {
  return (
    <Container>
      <BingoContextProvider valueList={NAMELIST} twoValues={TWOVALUES} event='bingo'>
        <BingoTab />
      </BingoContextProvider>
    </Container>
  );
};

export default PublicBingo;
