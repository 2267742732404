import Kagura from '../../assets/sprites/Kagura.png';
import style from './Kagura.module.css';
import Walking from '../walking.jsx';

const KaguraWalking = ({top, left}) => {
  return (
    <Walking
      container={style.walkingContainer}
      character='Kagura'
      sheet={Kagura}
      top={top}
      left={left}
      frames={4}
    />
  )
}

export default KaguraWalking;
