import { useState } from 'react';
import { Accordion, Badge, Button, Col, Row, Modal } from 'react-bootstrap';
import style from './CalendarComponent.module.css';
import useAuth from '../../utilities/AuthContext.jsx';
import CallApi from '../../utilities/ApiCallers.jsx';

const ROUTE = '/api/events/update-event';
const METHOD = 'PUT';
const D_ROUTE = '/api/events/mod/delete-event';
const D_METHOD = 'DELETE';

const ModTab = ({events}) => {
  const auth = useAuth();
  const user = auth.UserObj ? auth.UserObj : {user: 'user', id: '12345', token: 'adbsadfasdfaf'};
  const [modalShow, setModalShow] = useState(false);
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);
  const [displayMessage, setDisplayMessage] = useState('');

  const Approve = async (event) => {
    const temp = event.status;
    event.status = 'approved';
    try {
      const response = await CallApi(ROUTE, METHOD, event, user.token);
      if (response.ok) {
        setDisplayMessage('Approval successful!');
        handleModalShow();
      }
    } catch(err) {
      event.status = temp;
      setDisplayMessage('Oops! Something went wrong! Please reach out to a mod if the problem persists.');
      handleModalShow();
    }
  }

  const Decline = async (event) => {
    const temp = event.status;
    event.status = 'declined';
    try {
      const response = await CallApi(ROUTE, METHOD, event, user.token);
      if (response.ok) {
        setDisplayMessage('Decline successful!');
        handleModalShow();
      }
    } catch(err) {
      event.status = temp;
      setDisplayMessage('Oops! Something went wrong! Please reach out to a mod if the problem persists.');
      handleModalShow();
    }
  }

  const Delete = async (event) => {
    try {
      const response = await CallApi(D_ROUTE, D_METHOD, event, user.token);
      if (response.ok) {
        setDisplayMessage('Deletion successful!');
        handleModalShow();
      }
    } catch(err) {
      setDisplayMessage('Oops! Something went wrong! Please reach out to a mod if the problem persists.');
      handleModalShow();
    };
  };

  return (
    <>
    <Accordion>
      {events.length > 0
      ? events.map((event, index) => {
        let status = '';
        switch(event.status) {
          case 'approved':
            status = 'success';
            break;
          case 'pending':
            status = 'warning';
            break;
          case 'declined':
            status = 'danger';
            break;
          default:
            status = 'secondary';
        };

        return (
          <Accordion.Item key={event.eventName + index} eventKey={index}>
            <Accordion.Header>
              <Row>
                <Col xs='8'>
                  <Badge className={style[event.pairing]} style={{fontSize: '15px'}}>{event.eventName}</Badge>
                </Col>
                <Col xs='4'>
                  <Badge pill bg={status}>{event.status[0].toUpperCase() + event.status.substr(1)}</Badge>
                </Col>
              </Row>
            </Accordion.Header>
            <Accordion.Body>
              <Row className='mb-2'>
                <Col md='4'>
                  <b style={{fontWeight: 'bold'}}>Submitted by:</b>
                  &nbsp;
                  &nbsp;
                  {event.username}
                </Col>
                <Col>
                  <b style={{fontWeight: 'bold'}}>User ID:</b>
                  &nbsp;
                  &nbsp;
                  {event.userId}
                </Col>
              </Row>
              <Row className='mb-2'>
                <Col>
                  <b style={{fontWeight: 'bold'}}>Organizer:</b>
                  &nbsp;
                  &nbsp;
                  {event.organizer}
                </Col>
                <Col>
                  <b style={{fontWeight: 'bold'}}>Blog:</b>
                  &nbsp;
                  &nbsp;
                  {event.orgLink}
                </Col>
                <Col>
                  <b style={{fontWeight: 'bold'}}>Status:</b>
                  &nbsp;
                  &nbsp;
                  {event.status[0].toUpperCase() + event.status.substr(1)}
                </Col>
              </Row>
              <Row className='mb-2'>
                <Col>
                  <b style={{fontWeight: 'bold'}}>Start Date:</b>
                  &nbsp;
                  &nbsp;
                  {event.startDate}
                </Col>
                <Col>
                  <b style={{fontWeight: 'bold'}}>End Date:</b>
                  &nbsp;
                  &nbsp;
                  {event.endDate !== undefined && event.endDate !== '' ? event.endDate : 'None'}
                </Col>
                <Col>
                  <b style={{fontWeight: 'bold'}}>Reg/Sub Due Date:</b>
                  &nbsp;
                  &nbsp;
                  {event.regDate !== undefined && event.regDate !== '' ? event.regDate : 'None'}
                </Col>
              </Row>
              {event.status === 'pending'
              ? <Row>
                  <Col xs='6' md='8' xl='10'>
                    <Button onClick={() => Delete(event)}>
                      Delete
                    </Button>
                  </Col>
                  <Col xs='3' md='2' xl='1'>
                    <Button onClick={() => Approve(event)}>
                      Approve
                    </Button>
                  </Col>
                  <Col xs='3' md='2' xl='1'>
                    <Button onClick={() => Decline(event)}>
                      Decline
                    </Button>
                  </Col>
                </Row>
              : <Row>
                  <Col xs='6' md='8' xl='10'>
                    <Button onClick={() => Delete(event)}>
                      Delete
                    </Button>
                  </Col>
              </Row>
              }
            </Accordion.Body>
          </Accordion.Item>
        )
      })
    : <Row className='justify-content-center'>
        <Col className='d-flex justify-content-center' style={{color: 'white'}}>
          No events to display
        </Col>
      </Row>
    }
    </Accordion>
    <Modal
      show={modalShow}
      onHide={handleModalClose}
      aria-labelledby='contained-modal-success'
      centered
    >
      <Modal.Header>
        <Modal.Title>{displayMessage}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default ModTab;
