import { useState } from 'react';
import { Row, Col, Container, Button, Form } from 'react-bootstrap';
import { CopyBlock, dracula } from 'react-code-blocks';

const chapterHTML = (chapter, newHeader) => {
  return (
`#workskin #chapter-${chapter} .chapter.preface.group .title {
  visibility: hidden;
  line-height: 0;
}

#workskin #chapter-${chapter} .chapter.preface.group .title::after {
  content: "${newHeader}";
  visibility: visible;
  line-height: 1em;
  display: block;
}

`
  )
}

const ChapterGenerator = () => {
  const [prologue, setPrologue] = useState(false);
  const [epilogue, setEpilogue] = useState(false);
  const [chapters, setChapters] = useState("1")
  const [output, setOutput] = useState("")

  const handleSubmit = () => {
    let numb = 0
    let temp = ""
    try{
      numb = Number(chapters)
      if (numb < 1) {
        numb = 1;
      }
    } catch(err) {
      numb = 1;
    };

    if (prologue) {
      temp = chapterHTML('1', 'Prologue')
    };

    if (prologue && numb === 1) {
      temp += chapterHTML(2, 'Chapter 1');
    } else if (prologue && numb !== 1) {
      for (let x = 2; x <= numb+1; x++) {
        temp += chapterHTML(x, 'Chapter ' + (x-1));
      };
    } else if (numb === 1) {
      temp = chapterHTML(1, 'Chapter 1');
    } else {
      for (let x = 1; x <= numb; x++) {
        temp += chapterHTML(x, 'Chapter ' + x);
      };
    };

    if (prologue && epilogue && numb > 1) {
      temp += chapterHTML(numb + 2, 'Epilogue');
    } else if (prologue && epilogue && numb === 1) {
      temp += chapterHTML(3, 'Epilogue');
    } else if (epilogue && numb === 1) {
      temp += chapterHTML(2, 'Epilogue');
    } else if (epilogue) {
      temp += chapterHTML(numb + 1, 'Epilogue');
    };
    setOutput(temp)
  };

  return (
    <Container>
      <Row style={{color: 'white'}}>
        <Col md={'2'}>
          <Form.Check
            type='checkbox'
            id='prologueAsk'
            label='Add a prologue?'
            checked={prologue}
            onChange={() => setPrologue(!prologue)}
          />
        </Col>
        <Col md={'2'}>
          <Form.Check
            type='checkbox'
            id='epilogueAsk'
            label='Add an epilogue?'
            checked={epilogue}
            onChange={() => setEpilogue(!epilogue)}
          />
        </Col>
        <Col xs={'auto'}>
        <Form.Label htmlFor="chapterNumbers">How many chapters not including prologue and epilogue?</Form.Label>
        </Col>
        <Col md={'1'}>
          <Form.Control
            type='numeric'
            id='chapterNumbers'
            value={chapters}
            onChange={(e) => setChapters(e.target.value)}
          />
        </Col>
        <Col>
          <Button className='LibraryBtn' variant='outline-light' onClick={handleSubmit}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-emoji-kiss" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M12.493 13.368a7 7 0 1 1 2.489-4.858c.344.033.68.147.975.328a8 8 0 1 0-2.654 5.152 8.58 8.58 0 0 1-.81-.622Zm-3.731-3.22a13 13 0 0 0-1.107.318.5.5 0 1 1-.31-.95c.38-.125.802-.254 1.192-.343.37-.086.78-.153 1.103-.108.16.022.394.085.561.286.188.226.187.497.131.705a1.892 1.892 0 0 1-.31.593c-.077.107-.168.22-.275.343.107.124.199.24.276.347.142.197.256.397.31.595.055.208.056.479-.132.706-.168.2-.404.262-.563.284-.323.043-.733-.027-1.102-.113a14.87 14.87 0 0 1-1.191-.345.5.5 0 1 1 .31-.95c.371.12.761.24 1.109.321.176.041.325.069.446.084a5.609 5.609 0 0 0-.502-.584.5.5 0 0 1 .002-.695 5.52 5.52 0 0 0 .5-.577 4.465 4.465 0 0 0-.448.082Zm.766-.087-.003-.001-.003-.001c.004 0 .006.002.006.002Zm.002 1.867-.006.001a.038.038 0 0 1 .006-.002ZM6 8c.552 0 1-.672 1-1.5S6.552 5 6 5s-1 .672-1 1.5S5.448 8 6 8Zm2.757-.563a.5.5 0 0 0 .68-.194.934.934 0 0 1 .813-.493c.339 0 .645.19.813.493a.5.5 0 0 0 .874-.486A1.934 1.934 0 0 0 10.25 5.75c-.73 0-1.356.412-1.687 1.007a.5.5 0 0 0 .194.68ZM14 9.828c1.11-1.14 3.884.856 0 3.422-3.884-2.566-1.11-4.562 0-3.421Z"/>
            </svg>
          </Button>
        </Col>
      </Row>
      <Row>
        {
          output !== ""
          ? <CopyBlock
              text={output}
              language='CSS'
              showLineNumbers={true}
              theme={dracula}
              wrapLines={true}
              codeBlock
            />
          : <></>
        }
      </Row>
    </Container>
  );
}

export default ChapterGenerator;
