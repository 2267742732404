import { Row, Col } from 'react-bootstrap';
import EventButton from '../buttons/EventButton.jsx';
import dayjs from 'dayjs';
import React from 'react';

dayjs.extend(require('dayjs/plugin/customParseFormat'));
dayjs.extend(require('dayjs/plugin/duration'));

const buttonLength = (days, wrap) => {
  if (!wrap) {
    switch(days) {
      case 7:
        return '830%';
      case 6:
        return '710%';
      case 5:
        return '593%';
      case 4:
        return '465%';
      case 3:
        return '345%';
      case 2:
        return '222%';
      default:
        return '100%';
    };
  } else {
    switch(days) {
      case 7:
        return '856%';
      case 6:
        return '729%';
      case 5:
        return '607%';
      case 4:
        return '491.5%';
      case 3:
        return '363.5%';
      case 2:
        return '248%';
      default:
        return '121%';
    };
  }
};

const CalendarEventsLg = ({weekdays}) => {
  let arr = [];
  if (weekdays !== undefined && weekdays.length > 0) {
    for (let i = 0; i < weekdays.length; i++) {
      weekdays[i].events.forEach((event) => {
        arr.push(event);
      });
    };
  };
  const singleEvents = arr.filter((value, index, self) => self.findIndex(t => (JSON.stringify(t) === JSON.stringify(value)))===index);
  singleEvents.sort((a, b) => {
    if (a.startDate < b.startDate) {
      return -1;
    } else if (a.startDate > b.startDate) {
      return 1;
    } else {
      return 0;
    };
  });

  let rowHeight;
  let topSet;
  switch(singleEvents.length) {
    case 3:
      rowHeight = '100%';
      topSet = '0';
      break;
    case 2:
      rowHeight = '65%';
      topSet = '-11px';
      break;
    case 1:
      rowHeight = '33%';
      topSet = '-21px';
      break;
    default:
      rowHeight = '0%';
      topSet = '0';
  }

  return (
    <Row style={{height: rowHeight, position: 'relative', top: topSet}}>
      {weekdays.map((day, index) => {
        return (
          <Col key={index + day.date} style={{width: '135px'}}>
            {singleEvents.map((event, index) => {
              let size = '100%';
              let wrapBtn = false;
              if ((day.events.includes(event) || day.date > event.startDate)
                && (event.startDate === day.date || day.date === weekdays[0].date)) {
                  // Logic for button length if multiday event
                  if (event.endDate !== undefined && event.endDate !== '') {
                    // If there is an end date set the button length based on days of the event for the week
                    if (event.endDate <= weekdays[5].date) {
                      // Set length based on the last day of the event
                      size = buttonLength(1 + dayjs.duration(dayjs(event.endDate, 'YYYY-MM-DD').diff(dayjs(event.startDate <= weekdays[0].date ? weekdays[0].date: event.startDate), 'YYYY-MM-DD')).$d.days);
                    } else {
                      // Set length based on last day of week
                      const daysObj = dayjs.duration(dayjs(weekdays[6].date, 'YYYY-MM-DD').diff(dayjs(event.startDate <= weekdays[0].date ? weekdays[0].date : event.startDate), 'YYYY-MM-DD')).$d;
                      const numberOfDays = daysObj.hours !== 23
                        ? 1 + daysObj.days
                        : 2 + daysObj.days;
                      if (event.endDate !== weekdays[6].date) {
                        wrapBtn = true;
                        size = buttonLength(numberOfDays, wrapBtn);
                      } else {
                        size = buttonLength(numberOfDays);
                      };
                    }
                  };
                  return (
                    <EventButton
                      key={'display-' + event.eventName + day.date + index}
                      color={event.pairing} day={day.date}
                      event={event}
                      eventWidth={size}
                      spacer={false}
                      wrap={wrapBtn}
                    />
                  );
                } else {
                  // Set spacers and other handling
                  // If it failed for these reasons it doesn't need anything
                  if (!(day.events.includes(event) || day.date > event.startDate)) {
                    return <React.Fragment key={day.date + index}></React.Fragment>;
                  };
                  // Handle registration/submission date button
                  if (event.regDate !== undefined && event.regDate === day.date) {
                      return (
                        <EventButton
                          key={'reg-' + event.eventName + day.date + index}
                          color={event.pairing} day={day.date}
                          event={event}
                          eventWidth={size}
                          spacer={false}
                        />
                      );
                  };
                  // Create spacers for proper spacing
                  for (let i = 0; i < day.events.length; i++) {
                    if ((event.endDate >= day.events[i].startDate && event.endDate >= day.date)
                      || (day.events.length > 1 && singleEvents[0].endDate >= singleEvents[1].startDate)) {
                        return (
                          <EventButton
                            key={'spacer-' + event.eventName + day.date + index}
                            color={event.pairing} day={day.date}
                            event={event}
                            eventWidth={size}
                            spacer={true}
                          />
                      );
                    };
                  };
                  return <React.Fragment key={day.date + index}></React.Fragment>;
                };
            })}
          </Col>
        );
      })
      }
    </Row>
  );
};

export default CalendarEventsLg;
