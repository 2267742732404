import { Stack } from 'react-bootstrap';
import BingoSquare from './BingoSquare.jsx';

const BingoRow = ({row}) => {
  return (
    <Stack className='mx-auto' direction='horizontal'>
      <BingoSquare row={row} column={'0'} />
      <BingoSquare row={row} column={'1'} />
      <BingoSquare row={row} column={'2'} />
      <BingoSquare row={row} column={'3'} />
      <BingoSquare row={row} column={'4'} />
    </Stack>
  );
};

export default BingoRow;
