import { useEffect, useState } from 'react';
import { Container, Tabs, Tab, Row, Col, Dropdown, DropdownButton } from 'react-bootstrap';
import CalendarDisplay from '../../components/calendar/CalendarDisplay.jsx';
import useAuth from '../../utilities/AuthContext.jsx';
import SubmitTab from '../../components/calendar/SubmitTab.jsx';
import ModTab from '../../components/calendar/ModTab.jsx';
import EditTab from '../../components/calendar/EditTab.jsx';
import CallApi, { GetEvents } from '../../utilities/ApiCallers.jsx';
import LoadingAnimation from '../../components/animations/loadingAnimation.jsx';
import RefreshButton from '../../components/buttons/RefreshButton.jsx';

const TOKEN = 'LibraryCalendarStart';

const Calendar = () => {
  const auth = useAuth();
  const user = auth.UserObj ? auth.UserObj : {token: 'asdfadsfasdf', id: '12345', username: 'user'};
  const [Fawn] = useState(user.username === 'user' || user.id === '622865813136736265' || user.id === '329812232869380106' ? true : false);
  const [approvedEvents, setApprovedEvents] = useState([]);
  const [pendingEvents, setPendingEvents] = useState([]);
  const [birthdays, setBirthdays] = useState([]);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState('calendar');
  const [startDay, setStartDay] = useState(localStorage.getItem(TOKEN));

  const fetchApprovedEvents = async () => {
    const response = await GetEvents('approved-events', user.token);
    setApprovedEvents(response);
  };

  const fetchAllEvents = async () => {
    let response = await GetEvents('approved-events', user.token);
    setApprovedEvents(response);

    if (Fawn) {
      response = await GetEvents('mod/nonapproved-events', user.token);
      setPendingEvents(response);
    } else {
      response = await GetEvents('user-events/' + user.id, user.token);
      setPendingEvents(response);
    };
  };

  const fetchBirthdays = async () => {
    try {
      const response = await CallApi('/api/events/birthdays', 'GET', undefined, user.token);
      if (response.ok) {
        const bdays = await response.json();
        setBirthdays(bdays);
      };
    } catch(err) {
      // Let error pass silently
      // Empty array is default
    };
  };

  const setTab = async (key) => {
    setKey(key);
    setLoading(true);

    if (key === 'calendar') {
      await fetchApprovedEvents();
      if (birthdays.length === 0) {
        await fetchBirthdays();
      };
    } else if (key === 'submit') {
      // Do nothing
    } else {
      await fetchAllEvents();
    };

    setLoading(false);
  };

  const toggleStartDay = () => {
    if (startDay === null) {
      localStorage.setItem(TOKEN, 'monday');
      setStartDay('monday');
    } else {
      localStorage.removeItem(TOKEN);
      setStartDay(null);
    };
  };

  useEffect(() => {
    (async () => {
      await fetchApprovedEvents();
      await fetchBirthdays();
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      {loading
        ? <LoadingAnimation />
        :
        <Tabs activeKey={key} onSelect={async k => setTab(k)} className='mb-3'>
          <Tab tabClassName='LibraryBtn' eventKey='calendar' title='Calendar'>
            <Row className='justify-content-end mb-3'>
              <Col className='d-flex justify-content-end' xs='6'>
                <DropdownButton
                  bsPrefix='LibraryBtn btn btn-outline-light btn-sm'
                  id='calendar-dropdown-options'
                  title='Options'
                  size='sm'
                  autoClose='outside'
                >
                  <Dropdown.Item
                    className='LibraryBtn mx-auto'
                    as='button'
                    onClick={fetchApprovedEvents}
                  >
                    Refresh
                  </Dropdown.Item>
                  <Dropdown.Item
                    className='LibraryBtn mx-auto'
                    as='button'
                    onClick={toggleStartDay}
                  >
                    Toggle Start Day
                  </Dropdown.Item>
                </DropdownButton>
              </Col>
            </Row>
            <CalendarDisplay
              events={approvedEvents}
              startDay={startDay === null ? 'sunday' : 'monday'}
              bdays={birthdays}
            />
          </Tab>
          <Tab tabClassName='LibraryBtn' eventKey='submit' title='Event Submission'>
            <SubmitTab />
          </Tab>
          {!Fawn
            ?
              <Tab tabClassName='LibraryBtn' eventKey='edit' title='Your Submissions'>
                <Row className='justify-content-end'>
                  <Col className='d-flex justify-content-end' xs='6'>
                    <RefreshButton callback={fetchAllEvents} />
                  </Col>
                </Row>
                <EditTab events={pendingEvents} />
              </Tab>
            :
              <Tab tabClassName='LibraryBtn' eventKey='mod' title='Event Moderation'>
                <Row className='justify-content-end'>
                  <Col className='d-flex justify-content-end' xs='6'>
                    <RefreshButton callback={fetchAllEvents} size='sm' />
                  </Col>
                </Row>
                <ModTab events={pendingEvents.concat(approvedEvents)} />
              </Tab> 
          }
        </Tabs>
      }
    </Container>
  );
};

export default Calendar;
