import Urasue from '../../assets/sprites/Urasue.png';
import style from './Urasue.module.css';
import Walking from '../walking.jsx';

const UrasueWalking = ({top, left}) => {
  return (
    <Walking
      container={style.walkingContainer}
      character='Urasue'
      sheet={Urasue}
      top={top}
      left={left}
      frames={4}
    />
  )
}

export default UrasueWalking;
