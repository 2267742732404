import Kikyo from '../../assets/sprites/Kikyo.png';
import style from './Kikyo.module.css';
import Walking from '../walking.jsx';

const KikyoWalking = ({top, left}) => {
  return (
    <Walking
      container={style.walkingContainer}
      character='Kikyo'
      sheet={Kikyo}
      top={top}
      left={left}
      frames={4}
    />
  )
}

export default KikyoWalking;
