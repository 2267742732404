import { Carousel, Col } from 'react-bootstrap';
import inukag from '../assets/images/inukag_ship.png';
import mirsan from '../assets/images/mirsan_reading.png';
import kagkik from '../assets/images/kagkik_reading.png';
import togiza from '../assets/images/Inuparents_reading.png';
import narkik from '../assets/images/narkik_cropped.png';
import sesskagu from '../assets/images/sesskagu_cropped_clear.png';
import kogkag from '../assets/images/kogkag_reading.png';

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
}

const HomeCarousel = () => {
  const arr = [inukag, mirsan, kagkik, togiza, narkik, sesskagu, kogkag];
  shuffleArray(arr);
  return (
    <Col xs='10' md='8' xl='6'>
      <Carousel variant="dark">
        <Carousel.Item>
          <img
            className={'d-block w-100'}
            src={arr[0]}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className={'d-block w-100'}
            src={arr[1]}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className={'d-block w-100'}
            src={arr[2]}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className={'d-block w-100'}
            src={arr[3]}
            alt="Fourth slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className={'d-block w-100'}
            src={arr[4]}
            alt="Fifth slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className={'d-block w-100'}
            src={arr[5]}
            alt="Sixth slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className={'d-block w-100'}
            src={arr[6]}
            alt="Seventh slide"
          />
        </Carousel.Item>
      </Carousel>
    </Col>
  );
};

export default HomeCarousel;
