import dayjs from 'dayjs';

dayjs.extend(require('dayjs/plugin/weekday'));
dayjs.extend(require('dayjs/plugin/weekOfYear'));
dayjs.extend(require('dayjs/plugin/isBetween'));

const getNumberOfDaysInMonth = (year, month) => {
  return dayjs(`${year}-${month}-01`).daysInMonth();
}

const getWeekday = (date) => {
  return dayjs(date).weekday();
}

/*
 * Create the days of the current month
 */
export const CreateDaysForCurrentMonth = (year, month, events, bdays) => {
  /*
   * Create an empty array with indexes equal to the number of days in the month.
   * Map through the array creating a date object for every day of the month.
   */
  return [...Array(getNumberOfDaysInMonth(year, month))].map((day, index) => {
    const tempDay = dayjs(`${year}-${month}-${index + 1}`).format("YYYY-MM-DD");
    const passEvent = [];
    const birthdays = [];
    events.forEach(event => {
      if (event.regDate === tempDay) {
        passEvent.push(event);
      }
      if (event.endDate !== undefined && event.endDate !== '' && dayjs(tempDay).isBetween(event.startDate, event.endDate, null, '[]')) {
        passEvent.push(event);
      } else if (event.startDate === tempDay) {
        passEvent.push(event);
      }
    });
    bdays.forEach(bday => {
      if (bday.birthdate === tempDay.slice(5)) {
        birthdays.push(bday);
      };
    });
    return {
      date: tempDay,
      dayOfMonth: index + 1,
      isCurrentMonth: true,
      events: passEvent,
      birthdays: birthdays
    };
  });
};

/*
 * Create the days at the beginning of the calendar for the previous month
 */
export const CreateDaysForPreviousMonth = (year, month, currMonthDays, events, startSunday, bdays) => {
  // Decide if weeks need to start on Sunday or Monday and create needed days
  if (startSunday === 'sunday') {
    const firstDayOfTheMonthWeekday = getWeekday(currMonthDays[0].date);
    const previousMonth = dayjs(`${year}-${month}-01`).subtract(1, "month");
    const previousMonthLastMondayDayOfMonth = dayjs(currMonthDays[0].date)
      .subtract(firstDayOfTheMonthWeekday, "day")
      .date();

    return [...Array(firstDayOfTheMonthWeekday)].map((day, index) => {
      const tempDay = dayjs(
          `${previousMonth.year()}-${previousMonth.month() + 1}-${
            previousMonthLastMondayDayOfMonth + index
          }`
        ).format("YYYY-MM-DD");
      const passEvent = [];
      const birthdays = [];
      events.forEach(event => {
        if (event.regDate === tempDay) {
          passEvent.push(event);
        }
        if (event.endDate !== undefined && event.endDate !== '' && dayjs(tempDay).isBetween(event.startDate, event.endDate, null, '[]')) {
          passEvent.push(event);
        } else if (event.startDate === tempDay) {
          passEvent.push(event);
        };
      });
      bdays.forEach(bday => {
        if (bday.birthdate === tempDay.slice(5)) {
          birthdays.push(bday);
        };
      });
      return {
        date: tempDay,
        dayOfMonth: previousMonthLastMondayDayOfMonth + index,
        isCurrentMonth: false,
        events: passEvent,
        birthdays: birthdays
      };
    });
  } else {
    // Get the days for starting weeks on Monday
    const firstDayOfTheMonthWeekday = getWeekday(currMonthDays[0].date);
    const previousMonth = dayjs(`${year}-${month}-01`).subtract(1, "month");
    // Account for first day of the month on a Sunday
    const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
      ? firstDayOfTheMonthWeekday - 1
      : 6;

    const previousMonthLastMondayDayOfMonth = dayjs(currMonthDays[0].date)
      .subtract(visibleNumberOfDaysFromPreviousMonth, "day")
      .date();

    return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((day, index) => {
      const tempDay = dayjs(
          `${previousMonth.year()}-${previousMonth.month() + 1}-${
            previousMonthLastMondayDayOfMonth + index
          }`
        ).format("YYYY-MM-DD");
      const passEvent = [];
      const birthdays = [];
      events.forEach(event => {
        if (event.regDate === tempDay) {
          passEvent.push(event);
        }
        if (event.endDate !== undefined && event.endDate !== '' && dayjs(tempDay).isBetween(event.startDate, event.endDate, null, '[]')) {
          passEvent.push(event);
        } else if (event.startDate === tempDay) {
          passEvent.push(event);
        };
      });
      bdays.forEach(bday => {
        if (bday.birthdate === tempDay.slice(5)) {
          birthdays.push(bday);
        };
      });
      return {
        date: tempDay,
        dayOfMonth: previousMonthLastMondayDayOfMonth + index,
        isCurrentMonth: false,
        events: passEvent,
        birthdays: birthdays
      };
    });
  };
};

/*
 * Create the days at the end of the calendar for the next month
 */
export const CreateDaysForNextMonth = (year, month, currMonthDays, events, startSunday, bdays) => {
  // Decide if weeks need to start on Sunday or Monday and create needed days
  if (startSunday === 'sunday') {
    const lastDayOfTheMonthWeekday = getWeekday(
      `${year}-${month}-${currMonthDays.length}`
    );

    const nextMonth = dayjs(`${year}-${month}-01`).add(1, "month");

    const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
      ? 6 - lastDayOfTheMonthWeekday
      : 6;

    return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
      const tempDay = dayjs(
          `${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`
        ).format("YYYY-MM-DD")
      const passEvent = [];
      const birthdays = [];
      events.forEach(event => {
        if (event.regDate === tempDay) {
          passEvent.push(event);
        }
        if (event.endDate !== undefined && event.endDate !== '' && dayjs(tempDay).isBetween(event.startDate, event.endDate, null, '[]')) {
          passEvent.push(event);
        } else if (event.startDate === tempDay) {
          passEvent.push(event);
        };
      });
      bdays.forEach(bday => {
        if (bday.birthdate === tempDay.slice(5)) {
          birthdays.push(bday);
        };
      });
      return {
        date: tempDay,
        dayOfMonth: index + 1,
        isCurrentMonth: false,
        events: passEvent,
        birthdays: birthdays
      };
    });
  } else {
    // Get the days for the next month based on a Monday start
    const lastDayOfTheMonthWeekday = getWeekday(
      `${year}-${month}-${currMonthDays.length}`
    );

    const nextMonth = dayjs(`${year}-${month}-01`).add(1, "month");

    // Account for Monday start and get days needed
    const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
      ? 7 - lastDayOfTheMonthWeekday
      : lastDayOfTheMonthWeekday;

    return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
      const tempDay = dayjs(
          `${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`
        ).format("YYYY-MM-DD")
      const passEvent = [];
      const birthdays = [];
      events.forEach(event => {
        if (event.regDate === tempDay) {
          passEvent.push(event);
        }
        if (event.endDate !== undefined && event.endDate !== '' && dayjs(tempDay).isBetween(event.startDate, event.endDate, null, '[]')) {
          passEvent.push(event);
        } else if (event.startDate === tempDay) {
          passEvent.push(event);
        };
      });
      bdays.forEach(bday => {
        if (bday.birthdate === tempDay.slice(5)) {
          birthdays.push(bday);
        };
      });
      return {
        date: tempDay,
        dayOfMonth: index + 1,
        isCurrentMonth: false,
        events: passEvent,
        birthdays: birthdays
      };
    });
  };
};
