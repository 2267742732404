import { useEffect, useState } from 'react';
import { Button, Container, Col, Row, Form, Table, Spinner, ToastContainer, ToastBody, Toast } from 'react-bootstrap';
import useAuth from '../../utilities/AuthContext.jsx';
import CallAPI from '../../utilities/ApiCallers';
import LoadingAnimation from '../../components/animations/loadingAnimation.jsx';

const Leaderboard = () => {
  const auth = useAuth();
  const user = auth.UserObj ? auth.UserObj : {token: 'asdfadsfasdf', id: '12345', username: 'user', raffle: 'false'};
  const [leaderboard] = useState(new Map());
  const [names] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leader, setLeader] = useState(false);
  const [raffle, setRaffle] = useState(false);
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);


  useEffect(() => {
    setLoading(true);
    const getUserInfo = async () => {
      const response = await CallAPI('/api/bingo/get-settings/' + user.id, 'GET', undefined, user.token);
      const decoded = await response.json();
      const bingo = decoded.bingo2022;
      const raffle = decoded.raffle;
      setLeader(bingo !== undefined && bingo !== 'false' ? true : false);
      setRaffle(raffle !== undefined && raffle !== 'false' ? true : false);
    };

    const getLeaderboard = async () => {
      const response = await CallAPI('/api/bingo/get-leaderboard', 'GET', undefined, user.token);
      const decoded = await response.json();
      for (const key in decoded) {
        leaderboard.set(key, decoded[key]);
      };
      leaderboard.forEach((value, key) => {
        names.push([key, value]);
      });
      names.sort((a, b) => {
        return b[1] - a[1];
      });
      setLoading(false);
    };

    getUserInfo();
    getLeaderboard();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setChoice = (option) => {
    if (option === 'bingo') {
      setLeader(!leader);
    } else if (option === 'raffle') {
      setRaffle(!raffle);
    };
  };

  const saveChanges = async () => {
    setSaving(true);
    const response = await CallAPI(
      '/api/bingo/update-user',
      'PUT',
      {
        "id": user.id,
        "bingo2022": leader.toString(),
        "raffle": raffle.toString()
      },
      user.token
    );
    if (response.ok) {
      setSuccess(true);
    } else {
      setFail(true);
    };
    setSaving(false);
  };


  return (
    <Container>
      {!loading ?
      <>
        <Row>
          <Col>
            <Form.Check 
              type="switch"
              id="custom-switch"
              label="Opt into Leaderboard"
              checked={leader}
              onChange={() => setChoice('bingo')}
              style={{color: 'white'}}
            />
            <Form.Check
              className='mt-2'
              type="switch"
              id="custom-switch"
              label="Opt into Raffle"
              checked={raffle}
              onChange={() => setChoice('raffle')}
              style={{color: 'white'}}
            />
            <Button
              className='mt-2'
              onClick={saveChanges}
              disabled={saving}
            >
              {
              !saving
                ? 'Save'
                : <Spinner animation="border" />
              }
            </Button>
          </Col>
        </Row>
        <Row>
          <Table className='mt-2' striped bordered hover variant='dark'>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {
                names.map((name, index) => {
                  return (
                    <tr key={name[0] + name[1]}>
                      <th>{index + 1}</th>
                      <th>{name[0]}</th>
                      <th>{name[1]}</th>
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>
        </Row>
      </>
      : <LoadingAnimation />
      }
      <ToastContainer position={'top-center'}>
        <Toast
          show={success}
          onClose={() => setSuccess(false)}
          delay='5000'
          autohide
        >
          <ToastBody>Settings successfully saved!</ToastBody>
        </Toast>
        <Toast
          show={fail}
          onClose={() => setFail(false)}
          delay='5000'
          autohide
        >
          <ToastBody>Something went wrong. If it keeps happening please reach out.</ToastBody>
        </Toast>
      </ToastContainer>
    </Container>
  );
};

export default Leaderboard;
