import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Style from './Bingo.module.css';
import Inu from '../../components/assets/thumbnails/inu.png.jpg';
import Kagome from '../../components/assets/thumbnails/kagome.png.jpg';
import Miroku from '../../components/assets/thumbnails/miroku.png.jpg';
import Sango from '../../components/assets/thumbnails/sango.png.jpg';
import Shippo from '../../components/assets/thumbnails/shippo.png.jpg';
import Kikyo from '../../components/assets/thumbnails/kikyo.png.jpg';
import Kagura from '../../components/assets/thumbnails/kagura.png.jpg';
import Koga from '../../components/assets/thumbnails/koga.png.jpg';
import Bankotsu from '../../components/assets/thumbnails/bankotsu.png.jpg';
import Sess from '../../components/assets/thumbnails/sess.png.jpg';
import Naraku from '../../components/assets/thumbnails/naraku.png.jpg';
import Kohaku from '../../components/assets/thumbnails/kohaku.png.jpg';
import Kanna from '../../components/assets/thumbnails/kanna.png.jpg';


const arr = [
  Inu, Kagome, Miroku, Sango, Shippo, Kikyo, Kagura, Koga, Bankotsu, Sess, Naraku, Kohaku, Kanna,
  Inu, Kagome, Miroku, Sango, Shippo, Kikyo, Kagura, Koga, Bankotsu, Sess, Naraku, Kohaku, Kanna
];
const arr_length = arr.length;


const BingoDecoration = () => {
  const [flipped1, setFlipped1] = useState(false);
  const [flipped2, setFlipped2] = useState(false);
  const [flipped3, setFlipped3] = useState(false);
  const [flipped4, setFlipped4] = useState(false);
  const [flipped5, setFlipped5] = useState(false);
  const [flipped6, setFlipped6] = useState(false);
  const [flipped7, setFlipped7] = useState(false);
  const [image1, setImage1] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image2, setImage2] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image3, setImage3] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image4, setImage4] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image5, setImage5] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image6, setImage6] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image7, setImage7] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image8, setImage8] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image9, setImage9] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image10, setImage10] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image11, setImage11] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image12, setImage12] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image13, setImage13] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image14, setImage14] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image15, setImage15] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image16, setImage16] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image17, setImage17] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image18, setImage18] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image19, setImage19] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image20, setImage20] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image21, setImage21] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image22, setImage22] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image23, setImage23] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image24, setImage24] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image25, setImage25] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image26, setImage26] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image27, setImage27] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image28, setImage28] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image29, setImage29] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image30, setImage30] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image31, setImage31] = useState(arr[Math.floor(Math.random() * arr_length)]);
  const [image32, setImage32] = useState(arr[Math.floor(Math.random() * arr_length)]);

  const flipSeventh = () => {
    setFlipped7(flipped7 => !flipped7);
    setTimeout(() => {
      if (!flipped1) {
        setImage1(arr[Math.floor(Math.random() * arr_length)]);
        setImage3(arr[Math.floor(Math.random() * arr_length)]);
        setImage5(arr[Math.floor(Math.random() * arr_length)]);
        setImage7(arr[Math.floor(Math.random() * arr_length)]);
        setImage9(arr[Math.floor(Math.random() * arr_length)]);
        setImage11(arr[Math.floor(Math.random() * arr_length)]);
        setImage13(arr[Math.floor(Math.random() * arr_length)]);
        setImage15(arr[Math.floor(Math.random() * arr_length)]);
        setImage17(arr[Math.floor(Math.random() * arr_length)]);
        setImage19(arr[Math.floor(Math.random() * arr_length)]);
        setImage21(arr[Math.floor(Math.random() * arr_length)]);
        setImage23(arr[Math.floor(Math.random() * arr_length)]);
        setImage25(arr[Math.floor(Math.random() * arr_length)]);
        setImage27(arr[Math.floor(Math.random() * arr_length)]);
        setImage29(arr[Math.floor(Math.random() * arr_length)]);
        setImage31(arr[Math.floor(Math.random() * arr_length)]);
      } else {
        setImage2(arr[Math.floor(Math.random() * arr_length)]);
        setImage4(arr[Math.floor(Math.random() * arr_length)]);
        setImage6(arr[Math.floor(Math.random() * arr_length)]);
        setImage8(arr[Math.floor(Math.random() * arr_length)]);
        setImage10(arr[Math.floor(Math.random() * arr_length)]);
        setImage12(arr[Math.floor(Math.random() * arr_length)]);
        setImage14(arr[Math.floor(Math.random() * arr_length)]);
        setImage16(arr[Math.floor(Math.random() * arr_length)]);
        setImage18(arr[Math.floor(Math.random() * arr_length)]);
        setImage20(arr[Math.floor(Math.random() * arr_length)]);
        setImage22(arr[Math.floor(Math.random() * arr_length)]);
        setImage24(arr[Math.floor(Math.random() * arr_length)]);
        setImage26(arr[Math.floor(Math.random() * arr_length)]);
        setImage28(arr[Math.floor(Math.random() * arr_length)]);
        setImage30(arr[Math.floor(Math.random() * arr_length)]);
        setImage32(arr[Math.floor(Math.random() * arr_length)]);
      };
    }, 2000);
  };
  const flipSixth = () => {
    setFlipped6(flipped6 => !flipped6);
    setTimeout(flipSeventh, 90);
  };
  const flipFifth = () => {
    setFlipped5(flipped5 => !flipped5);
    setTimeout(flipSixth, 90);
  };
  const flipFourth = () => {
    setFlipped4(flipped4 => !flipped4);
    setTimeout(flipFifth, 90);
  };
  const flipThird = () => {
    setFlipped3(flipped3 => !flipped3);
    setTimeout(flipFourth, 90);
  };
  const flipSecond = () => {
    setFlipped2(flipped2 => !flipped2);
    setTimeout(flipThird, 90);
  };
  const flipFirst = () => {
    setFlipped1(flipped1 => !flipped1)
    setTimeout(flipSecond, 90);
  };

  useEffect(() => {
    const interval = setInterval(() => flipFirst(), 10000);
    return () => clearInterval(interval);
  });


  return(
    <>
      <Row className='mt-5' xs='4'>
        <Col>
          <div className={flipped1 ? [Style.card, Style.flipped].join(' ') : Style.card}>
            <div className={Style.cardButton} onClick={() => flipFirst()}>
              <img src={image1} className={Style.cardFront} alt='random character' />
              <img src={image2} className={Style.cardBack} alt='random character' />
            </div>
          </div>
        </Col>
        <Col>
          <div className={flipped2 ? [Style.card, Style.flipped].join(' ') : Style.card}>
            <div className={Style.cardButton}>
              <img src={image3} className={Style.cardFront} alt='random character' />
              <img src={image4} className={Style.cardBack} alt='random character' />
            </div>
          </div>
        </Col>
        <Col>
          <div className={flipped3 ? [Style.card, Style.flipped].join(' ') : Style.card}>
            <div className={Style.cardButton}>
              <img src={image5} className={Style.cardFront} alt='random character' />
              <img src={image6} className={Style.cardBack} alt='random character' />
            </div>
          </div>
        </Col>
        <Col>
          <div className={flipped4 ? [Style.card, Style.flipped].join(' ') : Style.card}>
            <div className={Style.cardButton}>
              <img src={image7} className={Style.cardFront} alt='random character' />
              <img src={image8} className={Style.cardBack} alt='random character' />
            </div>
          </div>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          <div className={flipped2 ? [Style.card, Style.flipped].join(' ') : Style.card}>
            <div className={Style.cardButton}>
              <img src={image9} className={Style.cardFront} alt='random character' />
              <img src={image10} className={Style.cardBack} alt='random character' />
            </div>
          </div>
        </Col>
        <Col>
          <div className={flipped3 ? [Style.card, Style.flipped].join(' ') : Style.card}>
            <div className={Style.cardButton}>
              <img src={image11} className={Style.cardFront} alt='random character' />
              <img src={image12} className={Style.cardBack} alt='random character' />
            </div>
          </div>
        </Col>
        <Col>
          <div className={flipped4 ? [Style.card, Style.flipped].join(' ') : Style.card}>
            <div className={Style.cardButton}>
              <img src={image13} className={Style.cardFront} alt='random character' />
              <img src={image14} className={Style.cardBack} alt='random character' />
            </div>
          </div>
        </Col>
        <Col>
          <div className={flipped5 ? [Style.card, Style.flipped].join(' ') : Style.card}>
            <div className={Style.cardButton}>
              <img src={image15} className={Style.cardFront} alt='random character' />
              <img src={image16} className={Style.cardBack} alt='random character' />
            </div>
          </div>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          <div className={flipped3 ? [Style.card, Style.flipped].join(' ') : Style.card}>
            <div className={Style.cardButton}>
              <img src={image17} className={Style.cardFront} alt='random character' />
              <img src={image18} className={Style.cardBack} alt='random character' />
            </div>
          </div>
        </Col>
        <Col>
          <div className={flipped4 ? [Style.card, Style.flipped].join(' ') : Style.card}>
            <div className={Style.cardButton}>
              <img src={image19} className={Style.cardFront} alt='random character' />
              <img src={image20} className={Style.cardBack} alt='random character' />
            </div>
          </div>
        </Col>
        <Col>
          <div className={flipped5 ? [Style.card, Style.flipped].join(' ') : Style.card}>
            <div className={Style.cardButton}>
              <img src={image21} className={Style.cardFront} alt='random character' />
              <img src={image22} className={Style.cardBack} alt='random character' />
            </div>
          </div>
        </Col>
        <Col>
          <div className={flipped6 ? [Style.card, Style.flipped].join(' ') : Style.card}>
            <div className={Style.cardButton}>
              <img src={image23} className={Style.cardFront} alt='random character' />
              <img src={image24} className={Style.cardBack} alt='random character' />
            </div>
          </div>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          <div className={flipped4 ? [Style.card, Style.flipped].join(' ') : Style.card}>
            <div className={Style.cardButton}>
              <img src={image25} className={Style.cardFront} alt='random character' />
              <img src={image26} className={Style.cardBack} alt='random character' />
            </div>
          </div>
        </Col>
        <Col>
          <div className={flipped5 ? [Style.card, Style.flipped].join(' ') : Style.card}>
            <div className={Style.cardButton}>
              <img src={image27} className={Style.cardFront} alt='random character' />
              <img src={image28} className={Style.cardBack} alt='random character' />
            </div>
          </div>
        </Col>
        <Col>
          <div className={flipped6 ? [Style.card, Style.flipped].join(' ') : Style.card}>
            <div className={Style.cardButton}>
              <img src={image29} className={Style.cardFront} alt='random character' />
              <img src={image30} className={Style.cardBack} alt='random character' />
            </div>
          </div>
        </Col>
        <Col>
          <div className={flipped7 ? [Style.card, Style.flipped].join(' ') : Style.card}>
            <div className={Style.cardButton}>
              <img src={image31} className={Style.cardFront} alt='random character' />
              <img src={image32} className={Style.cardBack} alt='random character' />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default BingoDecoration;
