import { useState } from 'react';
import { Button, FloatingLabel, Form, Modal, Spinner } from 'react-bootstrap';
import style from '../buttons/Button.module.css';
import { useBingoContext } from './BingoContext.jsx';
import useAuth from '../../utilities/AuthContext.jsx';
import CallApi from '../../utilities/ApiCallers.jsx';
import { checkBingo } from './BingoUtilites';


const ROUTE = '/api/bingo/submit-work';
const METHOD = 'POST';


const BingoSquare = ({row, column}) => {
  const state = useBingoContext();
  const { bingoCard, setBingoCard, setNoBingo, twoValues, event } = state;
  const index = `${row}${column}`;
  const [url, setUrl] = useState('');
  const [invalid, setInvalid] = useState(false);

  const [modalSubmitShow, setModalSubmitShow] = useState(false);
  const handleModalSubmitClose = () => setModalSubmitShow(false);
  const handleModalSubmitShow = () => setModalSubmitShow(true);
  const auth = useAuth();
  const user = auth.UserObj ? auth.UserObj : false;
  const [submit, setSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitFailure, setSubmitFailure] = useState(false);

  if (bingoCard[index] === undefined) {
    return <></>;
  };

  const deepCopy = (original, index) => {
    const copy = {};
    Object.keys(original).forEach((key) => {
      if (key === index) {
        copy[key] = {
          'value1': bingoCard[key].value1,
          'clickedStatus': !bingoCard[key].clickedStatus
        };
      } else {
        copy[key] = original[key];
      };
    });
    return copy;
  };

  const submitWork = async () => {
    const r = /^(ftp|http|https):\/\/[^ "]+$/;
    if (!r.test(url)) {
      setInvalid(() => true);
      return;
    };

    const copy = deepCopy(bingoCard, index);

    const cardInfo = {
      "card": copy,
      "userId": user.id,
      "status": "pending",
      "url": url,
      "square": index
    };

    try {
      setModalSubmitShow(false);
      setSubmit(true);
      const response = await CallApi(ROUTE, METHOD, cardInfo, user.token);
      if (response.ok) {
        setSubmitSuccess(true);
        setSubmit(false);
        setBingoCard(copy);
        setNoBingo(() => checkBingo(copy));
      };
    } catch(err) {
      setBingoCard(prevState => ({
        ...prevState,
        [index]: {
          'value1': bingoCard[index].value1,
          'clickedStatus': !bingoCard[index].clickedStatus
        }
      }));
      setSubmitFailure(true);
      setSubmit(false);
    };
  };

  // This function controls toggling and what happens when clicked
  const toggleBtn = () => {
    const curState = bingoCard[index].clickedStatus;
    if (event === 'bingo1122' && !curState) {
      // Display submission modal
      handleModalSubmitShow();
    };
    if (event === 'bingo') {
      setBingoCard(prevState => ({
        ...prevState,
        [index]: {
          'value1': bingoCard[index].value1,
          'value2': bingoCard[index].value2,
          'clickedStatus': !curState
        }
      }));
    };
  };

  if (twoValues === 'true') {
    return (
      <button
        className={!bingoCard[index].clickedStatus ?
          style.BingoButton
          : [style.BingoButton, style.BingoClicked].join(' ')}
        type='button'
        onClick={toggleBtn}
        name={index}
      >
        {bingoCard[index].value1}
        {index === '22'
          ? <></>
          : <br />
        }
        {index === '22'
          ? <></>
          : 'x'
        }
        {index === '22'
          ? <></>
          : <br />
        }
        {index === '22'
          ? <></>
          : bingoCard[index].value2
        }
      </button>
    );
  } else {
    return (
      <>
        <button
          className={!bingoCard[index].clickedStatus ?
            style.BingoButton
            : [style.BingoButton, style.BingoClicked].join(' ')}
          type='button'
          onClick={toggleBtn}
          name={index}
        >
          {bingoCard[index].value1}
        </button>
        <Modal
          show={modalSubmitShow}
          onHide={handleModalSubmitClose}
          aria-labelledby='story submission form'
          centered
        >
          <Modal.Header>
            <FloatingLabel
              controlId="floatingInput"
              label="Work URL"
            >
              <Form.Control
                type='text'
                isInvalid={invalid}
                onChange={(e) => setUrl(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid URL
              </Form.Control.Feedback>
            </FloatingLabel>
          </Modal.Header>
          <Modal.Footer>
            <Button variant="danger" onClick={handleModalSubmitClose}>
              Cancel
            </Button>
            <Button variant="success" onClick={submitWork}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
        show={submit}
        animation={false}
        aria-labelledby='loading'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Spinner animation="border" />
          </Modal.Title>
        </Modal.Header>
      </Modal>
      <Modal
        show={submitSuccess}
        onHide={() => setSubmitSuccess(false)}
        aria-labelledby='success'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            Your piece was successfully submitted!
          </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button variant='success' onClick={() => setSubmitSuccess(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={submitFailure}
          onHide={() => setSubmitFailure(false)}
          aria-labelledby='failure'
          centered
        >
          <Modal.Header>
            <Modal.Title>
              Something went wrong! If it continues, reach out to a mod.
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button variant='danger' onClick={() => setSubmitFailure(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
};

export default BingoSquare;
