import { createContext, useContext, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import CallApi from './ApiCallers.jsx';

const TOKEN = 'LibraryRefreshToken';

// User context
const UserContext = createContext(null);

/*
 * Function to get user object from response
 */
const decodeResponse = async (response) => {
  const tokenObj = await response.json();
  const decoded = jwtDecode(tokenObj.token);
  return {
    token: tokenObj.token,
    user: decoded.Username,
    issuer: decoded.iss,
    id: decoded.jti,
    roles: decoded.Roles,
    birthdate: decoded.Birthdate,
    active: decoded.Active // <- will be true if inu-mothership member
  };
};

/*
 * Submit code passed by Discord to get user
 */
const submitCode = async (code, location) => {
  const method = 'POST';
  const route = '/api/auth/login/code';
  const body = {'code':code, 'type': location}

  try {
    const response = await CallApi(route, method, body);
    if (response.ok) {
      return await decodeResponse(response);
    } else {
      throw new Error(response.statusText);
    };
  } catch (err) {
    return err;
  };
};

/*
 * Submit refresh token to get user for persistent login
 */
const submitRefresh = async () => {
  const method = 'GET';
  const route = '/api/auth/refresh';

  try {
    const response = await CallApi(route, method);
    if (response.ok) {
      return await decodeResponse(response);
    } else {
      throw new Error(response.statusText);
    };
  } catch (err) {
    return err;
  };
};

/*
 * Remove cookie used to track HttpOnly cookie
 */
const removeCookie = () => {
  // Set cookie exp to past to delete it
  localStorage.removeItem(TOKEN);
};

/*
 * Main function that handles authentication
 */
export const AuthProvider = ({children}) => {
  const [UserObj, setUserObj] = useState(null);

  /*
   * Function to sign in a user
   */
  const signIn = async (code, location, success, failure) => {
    try {
      const getUser = await submitCode(code, location);
      // Handle the call not returning a user
      if (!(getUser instanceof Error)) {
        setUserObj(getUser);
        localStorage.setItem(TOKEN, Date.now());
        success();
      } else {
        throw new Error('Error getting user');
      };
    } catch (err) {
      failure();
    };
  };

  /*
   * Function to log user back in
   */
  const refresh = async (refreshSuccess, refreshFailed) => {
    // Submit refresh token for login
    // If successful, send to whichever page they came from
    try {
      // Send refresh token
      const getUser = await submitRefresh();
      // Handle the call not returning a user
      // If user isn't an error, redirect to wanted page
      if (!(getUser instanceof Error)) {
        setUserObj(getUser);
        localStorage.setItem(TOKEN, Date.now());
        refreshSuccess();
      } else {
        throw new Error('Error getting user');
      };
    } catch (err) {
      // On failure, send to login page
      removeCookie();
      refreshFailed();
    };
  };

  /*
   * Function to sign out a user
   */
  const signOut = (callback) => {
    removeCookie();
    setUserObj(null)
    callback();
  };

  // Pass the functions to the user context provider
  const value = { UserObj, signIn, refresh, signOut };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

/*
 * Function to return the user context so that it can be used elsewhere
 */
const useAuth = () => {
  return useContext(UserContext);
};

/*
 * Function to sign a member back in while allowing non member access
 */
export const AllowAny = ({children}) => {
  const auth = useAuth();
  const location = useLocation();
  const loggedIn = localStorage.getItem(TOKEN);
  if (!auth.UserObj && loggedIn !== null && new Date(+loggedIn + 604800000) - Date.now() >= 0) {
    // Send user to refresh page
    // Handle loading screen and requests for logging user in
    // and then send them back to the page they wanted
    return <Navigate to='/refresh' state={{ from: location }} replace />;
  };
  return children;
};

/*
 * Function to authenticats the user
 */
export const RequireMember = ({children}) => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.UserObj || !auth.UserObj.active) {
    const loggedIn = localStorage.getItem(TOKEN);
    if (loggedIn !== null
      && new Date(+loggedIn + 604800000) - Date.now() >= 0
      && !auth.UserObj
    ) {
      // Send user to refresh page
      // Handle loading screen and requests for logging user in
      // and then send them back to the page they wanted
      return <Navigate to='/refresh' state={{ from: location }} replace />;
    }

    // User is not signed in so inform them to do that
    return <Navigate to='/membersonly' />;
  };

  return children;
};

export default useAuth;
