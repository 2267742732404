import { Row, Col } from "react-bootstrap";

const NameHeader = ({name}) => {
  return (
    <Row className='mt-1 mb-4'>
      <Col
        className='d-flex mb-2 mb-md-0'
        style={{color: 'white', fontSize: '50px', fontWeight: 'bold'}}
      >
        {name}
      </Col>
    </Row>
  );
};

export default NameHeader;
