import { Link } from 'react-router-dom';
import { Col,Nav } from 'react-bootstrap';

const NavLinks = ({activeTab}) => {
  return (
    <Col className='d-flex mt-3 mb-4' xs={{span: '12', order: '0'}} md={{span: '2', order: '0'}}>
      <Nav className='flex-column' variant='pills'>
        <Nav.Link
          as={Link}
          className='LibraryBtn'
          to='/settings/profile'
          active={activeTab === 'profile' ? true : false}
        >
          Your Profile
        </Nav.Link>
      </Nav>
    </Col>
  );
};

export default NavLinks;
