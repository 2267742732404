import { useState, useEffect } from 'react';

const Animation = ({image, character, total}) => {
  const [frame, setFrame] = useState({number: 1});

  useEffect(() => {
    const interval = setInterval(() => {
      if (frame.number === total) {
        setFrame({number: 1});
      } else {
        setFrame({number: frame.number + 1});
      }
    }, 167);

    return () => {clearInterval(interval);};
  }, [frame.number, total]);

  return (
    <img src={image} className={`${character}-${frame.number}`} alt='sprite' />
  )
};

export default Animation;
