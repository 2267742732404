import { Stack } from 'react-bootstrap';
import BingoRow from './BingoRow.jsx';


const BingoCard = () => {
  return (
    <Stack className='mt-5'>
      <BingoRow row={'0'} />
      <BingoRow row={'1'} />
      <BingoRow row={'2'} />
      <BingoRow row={'3'} />
      <BingoRow row={'4'} />
    </Stack>
  );
};

export default BingoCard;
