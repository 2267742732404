import Inuyasha from '../../assets/sprites/Inuyasha.png';
import style from './Inuyasha.module.css';
import Walking from '../walking.jsx';

const InuyashaWalking = ({top, left}) => {
  return (
    <Walking
      container={style.walkingContainer}
      character='Inuyasha'
      sheet={Inuyasha}
      top={top}
      left={left}
      frames={4}
    />
  )
}

export default InuyashaWalking;
