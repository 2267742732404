import Miroku from '../../assets/sprites/Miroku.png';
import style from './Miroku.module.css';
import Walking from '../walking.jsx';

const MirokuWalking = ({top, left}) => {
  return (
    <Walking
      container={style.walkingContainer}
      character='Miroku'
      sheet={Miroku}
      top={top}
      left={left}
      frames={4}
    />
  )
}

export default MirokuWalking;
