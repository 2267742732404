import Kohaku from '../../assets/sprites/Kohaku.png';
import style from './Kohaku.module.css';
import Walking from '../walking.jsx';

const KohakuWalking = ({top, left}) => {
  return (
    <Walking
      container={style.walkingContainer}
      character='Kohaku'
      sheet={Kohaku}
      top={top}
      left={left}
      frames={4}
    />
  )
}

export default KohakuWalking;
