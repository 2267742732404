import { useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner, Toast, ToastContainer, Alert } from 'react-bootstrap';
import { Formik } from 'formik';
import { object, string } from 'yup';
import useAuth from '../../utilities/AuthContext.jsx';
import NavLinks from '../../components/settings/NavLinks.jsx';
import NameHeader from '../../components/settings/NameHeader.jsx';
import CallApi from '../../utilities/ApiCallers.jsx';

const NUMBERS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14','15',
  '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];

const profileSchema = object().shape({
  month: string().notRequired(),
  day: string().notRequired()
});

const ProfileSettings = () => {
  const auth = useAuth();
  const user = auth.UserObj ? auth.UserObj : {user: 'user', id: '12345'};
  const [updateProfile, setUpdateProfile] = useState(false);
  const [updateProfileSuccess, setUpdateProfileSuccess] = useState(false);
  const [updateProfileFailure, setUpdateProfileFailure] = useState(false);

  const updateUser = async (values) => {
    setUpdateProfile(true);
    const userObj = {
      birthdate: values.month + '-' + values.day,
      id: user.id
    };

    try {
      const response = await CallApi('/api/user/update-user', 'PUT', userObj, user.token);
      if (response.ok) {
        setUpdateProfileSuccess(true);
        setUpdateProfile(false);
      };
    } catch(err) {
      setUpdateProfileFailure(true);
      setUpdateProfile(false);
    };
  };

  return (
    <Container className='pt-2'>
      {/* Split the container into the header and body */}
      <NameHeader name={user.user} />
      {/* Split the body into two columns */}
      <Row style={{color: 'white'}}>
        <NavLinks activeTab='profile' />
        <Col>
          <Row className='mb-3'>
            <Col
              style={{
              fontSize: '25px',
              borderBottom: '1px solid white',
              paddingBottom: '12px'
              }}
            >
              Profile Settings
            </Col>
          </Row>
          {/* Split this section into settings and photo option */}
          <Row>
            {/* First column: Actual settings */}
            <Col
              xs={{ span: 12, order: 2 }}
              md={{ span: 8, order: 1 }}
            >
              <Row>
                <Col>
                  <Formik
                    validationSchema={profileSchema}
                    onSubmit={values => updateUser(values)}
                    initialValues={{
                      month: user.birthdate !== undefined ? user.birthdate.slice(0,2) : '00',
                      day: user.birthdate !== undefined ? user.birthdate.slice(3) : '00',
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group controlId='profileSettings'>
                          <Form.Label>
                            <span style={{fontWeight: 'bold'}}>Birthdate</span>
                          </Form.Label>
                          <Row>
                            <Col xs='auto'>
                              <Form.Label>Month</Form.Label>
                              <Form.Select
                                aria-label='Birthday month select dropdown'
                                name='month'
                                value={values.month}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                style={{width: '70px'}}
                              >
                                <option className='birthdayMonth' value='00'>00</option>
                                {NUMBERS.slice(0, 12).map((month, index) => {
                                  return (
                                    <option key={index + index + month} value={month}>
                                      {month}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Col>
                            <Col xs='auto'>
                              <Form.Label>Day</Form.Label>
                              <Form.Select
                                aria-label='Birthday Day select dropdown'
                                name='day'
                                value={values.day}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                style={{width: '70px'}}
                              >
                                <option className='birthdayDay' value='00'>00</option>
                                {NUMBERS.map((day, index) => {
                                  if (['01', '03', '05', '07', '08', '10', '12'].indexOf(values.month) !== -1) {
                                    return (
                                      <option key={index + day + index} className='LibraryBtn' value={day}>
                                        {day}
                                      </option>
                                    );
                                  } else if (['04', '06', '09', '11'].indexOf(values.month) !== -1 && day !== '31') {
                                    return (
                                      <option key={index + day + index} className='LibraryBtn' value={day}>
                                        {day}
                                      </option>
                                    );
                                  } else if (values.month === '02' && day !== '29' && day !== '30' && day !== '31') {
                                    return (
                                      <option key={index + day + index} className='LibraryBtn' value={day}>
                                        {day}
                                      </option>
                                    );
                                  } else {
                                    return null;
                                  };
                                })}
                              </Form.Select>
                            </Col>
                          </Row>
                          <Row className='mt-4'>
                            <Col>
                              <Button className={`LibraryBtn`} variant='outline-light' type='submit' disabled={updateProfile}>
                                {!updateProfile
                                  ? <><svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='bi bi-send' viewBox='0 0 16 16'>
                                      <path d='M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z'/>
                                    </svg>
                                    &nbsp;
                                    Update profile</>
                                  : <>
                                    <Spinner
                                      as='span'
                                      animation='border'
                                      size='sm'
                                      role='update-status'
                                      aria-hidden='true'
                                    />
                                    Updating...
                                    </>
                                }
                                </Button>
                            </Col>
                          </Row>
                        </Form.Group>
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Row>
            </Col>
            {/* Second column: Image selection */}
            <Col
              xs={{ span: 12, order: 1 }}
              md={{ span: 4, order: 2 }}
            >
              {/*<Image
                roundedCircle='true'
                src={ImportedImage}
                style={{
                  width: '200px',
                  height: '200px',
                  objectFit: 'cover',
                  objectPosition: '-80px'
                }}
              />*/}
            </Col>
          </Row>
          {/* Add another <Row></Row> For additional settings */}
        </Col>
      </Row>
      <ToastContainer position='top-end' className='pe-2 pt-2'>
        <Toast
          show={updateProfileSuccess}
          onClose={() => setUpdateProfileSuccess(false)}
          variant='success'
          delay='5000'
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Inuyasha Library</strong>
          </Toast.Header>
          <Toast.Body>
            <Alert variant='success'>Profile successfully updated!</Alert>
          </Toast.Body>
        </Toast>
        <Toast
          show={updateProfileFailure}
          onClose={() => setUpdateProfileFailure(false)}
          variant='danger'
          delay='5000'
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Inuyasha Library</strong>
          </Toast.Header>
          <Toast.Body>
            <Alert variant='danger'>Error updating profile</Alert>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </Container>
  );
};

export default ProfileSettings;
