import React, { createContext, useContext, useState } from 'react';
import { useEffect } from 'react';
import { createBingoObj, randomizeValues } from './BingoUtilites.jsx';


const BingoContext = createContext(null);


export const BingoContextProvider = ({ children, valueList, twoValues, event }) => {
  const [bingoCard, setBingoCard] = useState({});
  const [noBingo, setNoBingo] = useState(false);

  useEffect(() => {
    const cardObj = sessionStorage.getItem(event);
    if (cardObj === null) {
      const newList = randomizeValues(valueList, twoValues);
      const bingoObj = createBingoObj(newList, twoValues);
      setBingoCard(bingoObj);
    } else {
      setBingoCard(JSON.parse(cardObj));
    };
  }, [valueList, twoValues, event]);

  return (
    <BingoContext.Provider
      value={{ bingoCard, setBingoCard, noBingo, setNoBingo, valueList, twoValues, event }}
    >
      {children}
    </BingoContext.Provider>
  );
};

export const useBingoContext = () => useContext(BingoContext);
