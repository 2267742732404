import { useEffect, useState } from 'react';
import { Accordion, Badge, Col, Row } from 'react-bootstrap';
import { GetBingoSubmissions } from '../../utilities/ApiCallers.jsx';


const UserTab = ({user}) => {
  const [submissions, setSubmissions] = useState([]);

  useEffect(() => {
    const getSubmissions = async () => {
      const response = await GetBingoSubmissions('user-submissions/' + user.id, user.token);
      setSubmissions(response);
    };
    getSubmissions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Accordion>
      {submissions.length > 0
      ? submissions.map((event, index) => {
        let status = '';
        switch(event.Status) {
          case 'approved':
            status = 'success';
            break;
          case 'pending':
            status = 'warning';
            break;
          default:
            status = 'secondary';
        };

        const userCard = JSON.parse(event.Card);

        return (
          <Accordion.Item key={event.UserId + index} eventKey={index}>
            <Accordion.Header>
              <Row>
                <Col xs='8'>
                  <Badge className={"temp"} style={{fontSize: '15px'}}>{event.UserId}</Badge>
                </Col>
                <Col xs='4'>
                  <Badge pill bg={status}>{event.Status[0].toUpperCase() + event.Status.substr(1)}</Badge>
                </Col>
              </Row>
            </Accordion.Header>
            <Accordion.Body>
              <Row className='mb-2'>
                <Col>
                  <b style={{fontWeight: 'bold'}}>User ID:</b>
                  &nbsp;
                  &nbsp;
                  {event.UserId}
                </Col>
              </Row>
              <Row className='mb-2'>
                <Row>
                  <Col style={{backgroundColor: event['Square'] === '00' ? 'purple' : userCard['00']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["00"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '01' ? 'purple' : userCard['01']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["01"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '02' ? 'purple' : userCard['02']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["02"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '03' ? 'purple' : userCard['03']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["03"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '04' ? 'purple' : userCard['04']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["04"]["value1"]}
                  </Col>
                </Row>
                <Row>
                  <Col style={{backgroundColor: event['Square'] === '10' ? 'purple' : userCard['10']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["10"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '11' ? 'purple' : userCard['11']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["11"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '12' ? 'purple' : userCard['12']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["12"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '13' ? 'purple' : userCard['13']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["13"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '14' ? 'purple' : userCard['14']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["14"]["value1"]}
                  </Col>
                </Row>
                <Row>
                  <Col style={{backgroundColor: event['Square'] === '20' ? 'purple' : userCard['20']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["20"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '21' ? 'purple' : userCard['21']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["21"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '22' ? 'purple' : userCard['22']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["22"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '23' ? 'purple' : userCard['23']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["23"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '24' ? 'purple' : userCard['24']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["24"]["value1"]}
                  </Col>
                </Row>
                <Row>
                  <Col style={{backgroundColor: event['Square'] === '30' ? 'purple' : userCard['30']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["30"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '31' ? 'purple' : userCard['31']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["31"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '32' ? 'purple' : userCard['32']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["32"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '33' ? 'purple' : userCard['33']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["33"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '34' ? 'purple' : userCard['34']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["34"]["value1"]}
                  </Col>
                </Row>
                <Row>
                  <Col style={{backgroundColor: event['Square'] === '40' ? 'purple' : userCard['40']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["40"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '41' ? 'purple' : userCard['41']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["41"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '42' ? 'purple' : userCard['42']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["42"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '43' ? 'purple' : userCard['43']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["43"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '44' ? 'purple' : userCard['44']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["44"]["value1"]}
                  </Col>
                </Row>
              </Row>
              <Row className='mb-2'>
                <Col>
                  <b style={{fontWeight: 'bold'}}>URL:</b>
                  &nbsp;
                  &nbsp;
                  <a href={event['Url']} target='_blank' rel='noreferrer'>{event['Url']}</a>
                </Col>
              </Row>
              <Row>
                <Col xs='6' md='3' xl='2'>
                  Score: {event['Score']}
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        )
      })
    : <Row className='justify-content-center'>
        <Col className='d-flex justify-content-center' style={{color: 'white'}}>
          No events to display
        </Col>
      </Row>
    }
    </Accordion>
  );
};

export default UserTab;
