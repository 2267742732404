import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../utilities/AuthContext';
import LoadingAnimation from '../../components/animations/loadingAnimation';

// Submit refresh token to log user back in
// On success send them back to the page they were trying to access otherwise home
// Add code to send user to login page if refresh fails
const Refresh = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const from = location.state?.from?.pathname || '/';

  useEffect(() => {
    // Pass a callback for success or failed refresh
    auth.refresh(
      () => {
        navigate(from, { replace: true });
      },
      () => {
        navigate('/login');
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Display loading image or spinner
  return <LoadingAnimation />;
};

export default Refresh;
