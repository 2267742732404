import { useState } from 'react';
import dayjs from 'dayjs';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import style from './CalendarComponent.module.css';

const DaySm = ({day}) => {
  const TODAY = dayjs().format("YYYY-MM-DD");
  const currentMonth = day.isCurrentMonth ? `${style.Day}` : `${style.NotCurrentMonth} ${style.Day}`;
  const currentDay = day.date === TODAY ? style.CurrentDay : '';
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dateObj = new Date(day.date);

  return (
    <Row>
    <button
      key={`day${day.dayOfMonth}`}
      className={`${currentMonth} ${currentDay}`}
      type='button'
      onClick={handleShow}
    >
      <Row
        style={{transform: 'translate(-8px, -25px)', fontSize: '12px'}} xs='2' className='mt-4'
      >
        <Col>
          {day.dayOfMonth}
        </Col>
        {day.birthdays.length !== 0
          ? <Col>
              🎉
            </Col>
          : null
        }
      </Row>
      <Row>
        {day.events.length !== 0
          ? day.events.map((event, index) => {
              return (
                <Col
                  key={event.eventName + index + day.date}
                  xs='auto'
                  style={{position: 'relative'}}
                  className='d-flex px-1 px-md-0 mx-md-2'
                >
                  <span className={[style.Event, style[event.pairing]].join(' ')} />
                </Col>
              );
            })
          : null
        }
      </Row>
    </button>

    <Modal
      show={show}
      onHide={handleClose}
      centered
      aria-labelledby='event-display-modal'
    >
      <Modal.Header>
        <Modal.Title>
          {dayjs(dateObj.setDate(dateObj.getDate() + 1)).format("YYYY MMMM DD")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={style.ModalSpacing}>
        {
          day.birthdays.length !== 0
          ? <>
              <Modal.Title>Happy Birthday!</Modal.Title>
              {day.birthdays.map((member) => {
                return `${member.name}\n`;})}
            </>
          : null
        }
        {
          day.events.length !== 0
          ? day.events.map((event, index) => {
              const dailyTheme = event.themes !== undefined && event.themes.size !== 0 ? event.themes.get(day.date) : '';
              return (
                <div key={event.eventName + index + day.date}>
                  <Modal.Title>
                    {event.eventName}
                  </Modal.Title>
                  {dailyTheme !== '' && dailyTheme !== undefined ? 'Daily theme: ' + dailyTheme + '\n': ''}
                  {event.regDate === day.date ? 'LAST DAY TO REGISTER!!!\n' : ''}
                  {`Pairing: ${event.pairing}\n`}
                  {`Organizer: ${event.organizer}\n`}
                  <a href={event.orgLink} target='_blank' rel='noreferrer'>Contact</a>
                </div>
              )
            })
          : 'There is nothing on the calendar for today!'
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </Row>
  );
};

export default DaySm;
