import { Row, Col } from 'react-bootstrap';
import style from './CalendarComponent.module.css';

const SUN_NAMES_ARR = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const MON_NAMES_ARR = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const CalendarDayHeader = ({startDay}) => {
  let NAMES_ARR;
  if (startDay === 'sunday') {
    NAMES_ARR = SUN_NAMES_ARR;
  } else {
    NAMES_ARR = MON_NAMES_ARR;
  };

  return (
    <Row className='mx-auto'>
      {NAMES_ARR.map((day) => {
        return <Col key={`header_${day}`} className={style.DayHeader}>{day}</Col>
      })}
    </Row>
  );
};

export default CalendarDayHeader;
