export const createBingoObj = (valueList, twoValues) => {
  const bingoObj = {};
  let index = 0;

  for (let row of ['0', '1', '2', '3', '4']) {
    for (let column of ['0', '1', '2', '3', '4']) {
      if (row === '2' && column === '2') {
        bingoObj['22'] = {
          'value1': 'Free Space',
          'clickedStatus': false
        };
      } else {
        if (twoValues === 'true') {
          bingoObj[row + column] = {
            'value1': valueList[index],
            'value2': valueList[index + 1],
            'clickedStatus': false
          };
        } else {
          bingoObj[row + column] = {
            'value1': valueList[index],
            'clickedStatus': false
          };
        };

        index += 2;
      };
    };
  };

  return bingoObj;
};

export const randomizeValues = (valueList, twoValues) => {
  let displayNames = [];

  const shuffleNames = (nameList) => {
    if (twoValues) {
      const newList = [];
      while (newList.length < 48) {
        const firstName = nameList[Math.floor(Math.random()*nameList.length)];
        let secondName = nameList[Math.floor(Math.random()*nameList.length)];

        while (firstName === secondName) {
          secondName = nameList[Math.floor(Math.random()*nameList.length)];
        };

        newList.push(firstName, secondName);
      };

      displayNames = newList;
    } else {
      const newList = [];
      while (newList.length < 48) {
        let word = nameList[Math.floor(Math.random()*nameList.length)];

        while (newList.includes(word)) {
          word = nameList[Math.floor(Math.random()*nameList.length)];
        };

        newList.push(word);
      };

      displayNames = newList;
    };
  };

  shuffleNames(valueList);
  return displayNames;
};

export const checkBingo = (card) => {
  let bingo = true;
  const s00 = card["00"].clickedStatus;
  const s11 = card["11"].clickedStatus;
  const s22 = card["22"].clickedStatus;
  const s33 = card["33"].clickedStatus;
  const s44 = card["44"].clickedStatus;
  const s04 = card["04"].clickedStatus;
  const s40 = card["40"].clickedStatus;
  const s13 = card["13"].clickedStatus;
  const s31 = card["31"].clickedStatus;
  // Four corners
  if (s00 && s04 && s40 && s44 && s22) {
    bingo = false;
    return;
  };
  // Left to right diagonal
  if (s00 && s11 && s22 && s33 && s44) {
    bingo = false;
    return;
  };
  // Right to left diagonal
  if (s04 && s13 && s22 && s31 && s40) {
    bingo = false;
    return;
  };
  // Horizontal and vertical
  const arr = ["0", "1", "2", "3", "4"];
  // Check for Horizontal bingo
  arr.forEach((row) => {
    const s0 = card[row + "0"].clickedStatus;
    const s1 = card[row + "1"].clickedStatus;
    const s2 = card[row + "2"].clickedStatus;
    const s3 = card[row + "3"].clickedStatus;
    const s4 = card[row + "4"].clickedStatus;
    if (s0 && s1 && s2 && s3 && s4) {
      bingo = false;
      return;
    };
    // Check for vertical bingo
    if (row === "0") {
      arr.forEach((col) => {
        const v0 = card[row + col].clickedStatus;
        const v1 = card["1" + col].clickedStatus;
        const v2 = card["2" + col].clickedStatus;
        const v3 = card["3" + col].clickedStatus;
        const v4 = card["4" + col].clickedStatus;
        if (v0 && v1 && v2 && v3 && v4) {
          bingo = false;
          return;
        };
      });
    };
  });
  return bingo;
};
