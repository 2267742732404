import Kagome from '../../assets/sprites/Kagome.png';
import style from './Kagome.module.css';
import Walking from '../walking.jsx';

const KagomeWalking = ({top, left}) => {
  return (
    <Walking
      container={style.walkingContainer}
      character='Kagome'
      sheet={Kagome}
      top={top}
      left={left}
      frames={4}
    />
  )
}

export default KagomeWalking;
