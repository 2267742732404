import { createButton } from 'react-social-login-buttons';

const config = {
  text: "Log in with Tumblr",
  icon: 'tumblr',
  iconFormat: name => `fab fa-${name}`,
  style: { background: "#34465D" },
  activeStyle: { background: "#293e69" }
};

const TumblrLoginButton = createButton(config);


export default TumblrLoginButton;
