import Sango from '../../assets/sprites/Sango & Kirara.png';
import style from './Sango.module.css';
import Walking from '../walking.jsx';

const SangoWalking = ({top, left}) => {
  return (
    <Walking
      container={style.walkingContainer}
      character='Sango'
      sheet={Sango}
      top={top}
      left={left}
      frames={4}
    />
  )
}

export default SangoWalking;
