import { Button } from 'react-bootstrap';
import { useBingoContext } from './BingoContext.jsx';

export const BingoSave = ({title}) => {
  const state = useBingoContext();
  const { bingoCard } = state;

  const saved = () => {
    localStorage.setItem(title, JSON.stringify(bingoCard));
  };

  return (
    <Button
      className='mb-2 LibraryBtn'
      onClick={saved}
      variant='outline-light'
    >
      Save
    </Button>
  );
};

export const BingoLoad = ({cardName}) => {
  const state = useBingoContext();
  const { setBingoCard } = state;

  const load = () => {
    const cardObj = localStorage.getItem(cardName);
    setBingoCard(JSON.parse(cardObj));
  };

  return (
    <Button
      className='mb-2 LibraryBtn'
      onClick={load}
      variant='outline-light'
    >
      Load
    </Button>
  );
};

export const BingoDelete = ({cardName}) => {
  const del = () => {
    localStorage.removeItem(cardName);
  };

  return (
    <Button
      className='mb-2 LibraryBtn'
      onClick={del}
      variant='outline-light'
    >
      Delete
    </Button>
  );
};
