import { useState } from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import useAuth from '../../utilities/AuthContext.jsx';
import LoadingAnimation from '../../components/animations/loadingAnimation.jsx';
import BingoTab from './BingoTab.jsx'
import ModTab from './ModTab.jsx';
import { BingoContextProvider } from '../../components/bingo/BingoContext.jsx';
import { GetBingoSubmissions } from '../../utilities/ApiCallers.jsx';
import Leaderboard from './Leaderboard.jsx';
import UserTab from './UserTab.jsx';


const NAMELIST = [
  "Cycle","True Love","Mountain","Loathing","Waterfall","Waiting","Determination","Antihero","Tricks",
  "Peace","Power","Aloof","Marriage","Water","Well","Student","Wolf","Craft","Redemption","Mark","Purity",
  "Jyaki","Manifest","Trust","Excuses","Misery","Poison","Shatter","Holy","Sight","String","Wife",
  "Compassion","Mind","Allies","Learning","Pup","Promise","Siblings","Web","Pride","Skeleton","Bow",
  "Helpless","Prayer","Devotion","Control","Lightning","Free Will","Story","Inheritance","Honor",
  "Reincarnation","Past Life","Wind","Hunt","Underworld","Fashion","Chosen","Deception","Yolk","Love",
  "Persevere","Test","Fidelity","Priestess","Healing","Moonlight","Wrath","Ancient","Unnatural",
  "Vanity","Indulge","Sacrifice","Skirt","Uniform","Forest","Conviction","Survival","Shelter","Hate",
  "Worth","Training","Summit","Vortex","Vengeance","Cub","Goshinboku","Black","Husband","Fur","Journey",
  "Touch","Provide","Hanyō","Unrequited","Lust","Reflection","Meal","Spells","Illusion","Necklace",
  "Bigotry","Growth","Strength","Charm","Pack","Afterlife","Wisdom","Epic","Desire","Blade","Content",
  "Argument","Infidelity","Equals","Master","Shrine","Storm","Struggle","Goodbye","Failure","Ghost",
  "Mis-understanding","Bathing","Longing","Garden","Regrets","Responsibility","Heart","Honesty","Evil",
  "Home","Worship","Clay","Flame","Nobility","Supreme","Soulmates","Father","Leap","Flight","Daiyōkai",
  "Overpower","Courage","Magic","Feudal","Dog","Clandestine","Kami","Past","Mate","Brother","Happiness",
  "Superiority","Armor","Ancestors","Price","Unconditional","Tokyo","Wanderer","Acceptance","Pain","Hut",
  "Monk","Grit","Bandit","Reiki","Lies","Sunset","Resurrection","Kindness","Life-force","Corruption","Ocean",
  "Samurai","Barrage","Light","Freedom","Sit","Punch","Defeat","Soul","Sword","Castle","Dragon","Found Family",
  "Weapon","Chaos","Mask","Formidable","Grief","Nightmare","Fate","Cave","Hardship","Covet","Red","Reunite",
  "Claws","Jewel","Skull","Tomb","Guardian","Hero","Safety","Hurt","Sharing","Centuries","Hunger","Kill","Void",
  "Hot Spring","Adventure","Defiance","Overcome","Fire","Alone","Future","Adamant","Nirvana","Tame","Servant",
  "Leader","Wish","Moon","Imp","Cow","Beach","Puppets","Comfort","Slayer","Children","Rescue","Kiss","Fear","Yōki",
  "Sleep","Mother","Life","Infant","Bones","Return","Bridge","Battle","Tradition","Humanity","Destiny","Memory",
  "Ramen","Spiritual","Salvation","Support","Yōkai","Medicine","Confrontation","Martyr","Acorn","Inferiority",
  "Rivalry","Fox","Time","Fury","Scent","Sister","Confidence","Karma","Protect","Mischief","Crush","Seal",
  "Arrow","Backlash","Sinister","Hubris","Tragedy","Declaration","Virtue","Victory","Darkness","Envy","Fangs",
  "Betrayal","Blood","Secrets","Traitor","Friendship","Prejudice","Revenge","Sunrise","Boar","Purple","Curses",
  "Weakness","Enemies","Offering","Wild","Food","Conflict","Instinct","Duality","Steal","Crescent","Modern",
  "Spirit","Scars","Tears","Apathy","Reanimation","Death","Legacy","Trouble","Greed","Lessons","Damned","Mushroom",
  "Force","Spider","Beloved","Doubt","Ward","Staff","Crest","Childhood","Ache","Present","Ferocity","Shenanigans",
  "Belonging","White","Forgive","Unholy","Care","Gifts","Sincerity","Injured","Kimono","Superficial","Revelation",
  "Barrier","Truth","Village","Wave","Vices","Legend","Sullied","Flowers","Antithesis","Broken","Forge","Dream",
  "Absolution","Miasma","Family","Transformation","Stars","Trap","Loyalty","Loss","Blame","Possession","Guilt",
  "Cat","Shackles","Meido"
];
const TWOVALUES = false;
const ALLSUBMISSIONS = 'all-submissions';


const Inuvember = () => {
  const auth = useAuth();
  const user = auth.UserObj ? auth.UserObj : false;
  const [Mod] = useState(
    user.id === '622865813136736265' || user.id === '329812232869380106' || user.id === '286619766192603137'
    || user.id === '796112444757114910' || user.id === '580134644813398026' || user.id === '727667902656020542'
    || user.id === '326588591113240576' || user.id === '339568729845137408'
    ? true : false
  );
  const [key, setKey] = useState('bingo-card');
  const [loading, setLoading] = useState(false);
  const [submissions, setSubmissions] = useState([]);

  const setTab = async (key) => {
    setKey(key);
    setLoading(true);

    if (key === 'leaderboard') {
      // await fetchApprovedEvents();
      // if (birthdays.length === 0) {
      //    await fetchBirthdays();
      // };
    } else if (key === 'all-submissions') {
      const response = await GetBingoSubmissions(ALLSUBMISSIONS, user.token);
      setSubmissions(response);
    } else if (key === 'review') {
      // await fetchAllEvents();
    };

    setLoading(false);
  };

  return (
    <Container>
      {loading
        ? <LoadingAnimation />
        :
        <Tabs activeKey={key} onSelect={async k => setTab(k)} className='mb-3'>
          <Tab tabClassName='LibraryBtn' eventKey='bingo-card' title='Bingo Card'>
            <BingoContextProvider valueList={NAMELIST} twoValues={TWOVALUES} event='bingo1122'>
              <BingoTab />
            </BingoContextProvider>
          </Tab>
          <Tab tabClassName='LibraryBtn' eventKey='leaderboard' title='Leader Board'>
            <Leaderboard />
          </Tab>
          {user
            ?
              <Tab tabClassName='LibraryBtn' eventKey='user-submissions' title='Your Submissions'>
                <UserTab user={user} />
              </Tab>
            :
              <></>
          }
          {
            Mod
            ? <Tab tabClassName='LibraryBtn' eventKey='all-submissions' title='All Submissions'>
              <ModTab submissions={submissions} mod={user} />
            </Tab>
            : <></>
          }
        </Tabs>
      }
    </Container>
  );
};

export default Inuvember;
