import { useState } from 'react';
import { Accordion, Badge, Button, Col, Row, Modal, Form } from 'react-bootstrap';
import useAuth from '../../utilities/AuthContext.jsx';
import CallApi from '../../utilities/ApiCallers.jsx';

const ROUTE = '/api/bingo/update-submission';
const METHOD = 'PUT';

const ModTab = ({submissions, mod}) => {
  const auth = useAuth();
  const user = auth.UserObj ? auth.UserObj : {user: 'user', id: '12345', token: 'adbsadfasdfaf'};
  const [modalShow, setModalShow] = useState(false);
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);
  const [displayMessage, setDisplayMessage] = useState('');
  const [score, setScore] = useState(4);
  const [bonus, setBonus] = useState(1);
  const [base, setBase] = useState(4);
  const [multiplier, setMultiplier] = useState(1);
  const [finalScore, setFinalScore] = useState('');

  submissions.sort((a, b) => {
    if (a.Status === 'pending' && b.Status === 'accepted') {
      return -1;
    } else if (a.Status === 'accepted' && b.Status === 'pending') {
      return 1;
    };
    return 0;
  });

  const calculateScore = (elem, num) => {
    switch(elem) {
      case 'base':
        setBase(num);
        setScore((num * bonus) * multiplier)
        break;
      case 'bonus':
        setBonus(num);
        setScore((num * base) * multiplier)
        break;
      case 'multiplier':
        setMultiplier(num);
        setScore((base * bonus) * num)
        break;
      default:
        console.log("Something went wrong");
    }
  };

  const Approve = async (event, fScore) => {
    event.Status = 'approved';
    event.Score = fScore;
    event.ModId = mod.id;
    event.Mod = mod.user
    try {
      const response = await CallApi(ROUTE, METHOD, event, user.token);
      if (response.ok) {
        setDisplayMessage('Approval successful!');
        handleModalShow();
      }
    } catch(err) {
      event.Status = 'pending';
      setDisplayMessage('Oops! Something went wrong! Please reach out to Yuki if the problem persists.');
      handleModalShow();
    };
  };

  return (
    <>
    <Accordion>
      {submissions.length > 0
      ? submissions.map((event, index) => {
        let status = '';
        switch(event.Status) {
          case 'approved':
            status = 'success';
            break;
          case 'pending':
            status = 'warning';
            break;
          default:
            status = 'secondary';
        };

        const userCard = JSON.parse(event.Card);

        return (
          <Accordion.Item key={event.UserId + index} eventKey={index}>
            <Accordion.Header>
              <Row>
                <Col xs='8'>
                  <Badge className={"temp"} style={{fontSize: '15px'}}>{event.UserId}</Badge>
                </Col>
                <Col xs='4'>
                  <Badge pill bg={status}>{event.Status[0].toUpperCase() + event.Status.substr(1)}</Badge>
                </Col>
              </Row>
            </Accordion.Header>
            <Accordion.Body>
              <Row className='mb-2'>
                <Col>
                  <b style={{fontWeight: 'bold'}}>User ID:</b>
                  &nbsp;
                  &nbsp;
                  {event.UserId}
                </Col>
              </Row>
              <Row className='mb-2'>
                <Row>
                  <Col style={{backgroundColor: event['Square'] === '00' ? 'purple' : userCard['00']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["00"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '01' ? 'purple' : userCard['01']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["01"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '02' ? 'purple' : userCard['02']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["02"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '03' ? 'purple' : userCard['03']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["03"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '04' ? 'purple' : userCard['04']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["04"]["value1"]}
                  </Col>
                </Row>
                <Row>
                  <Col style={{backgroundColor: event['Square'] === '10' ? 'purple' : userCard['10']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["10"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '11' ? 'purple' : userCard['11']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["11"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '12' ? 'purple' : userCard['12']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["12"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '13' ? 'purple' : userCard['13']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["13"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '14' ? 'purple' : userCard['14']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["14"]["value1"]}
                  </Col>
                </Row>
                <Row>
                  <Col style={{backgroundColor: event['Square'] === '20' ? 'purple' : userCard['20']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["20"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '21' ? 'purple' : userCard['21']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["21"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '22' ? 'purple' : userCard['22']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["22"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '23' ? 'purple' : userCard['23']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["23"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '24' ? 'purple' : userCard['24']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["24"]["value1"]}
                  </Col>
                </Row>
                <Row>
                  <Col style={{backgroundColor: event['Square'] === '30' ? 'purple' : userCard['30']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["30"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '31' ? 'purple' : userCard['31']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["31"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '32' ? 'purple' : userCard['32']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["32"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '33' ? 'purple' : userCard['33']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["33"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '34' ? 'purple' : userCard['34']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["34"]["value1"]}
                  </Col>
                </Row>
                <Row>
                  <Col style={{backgroundColor: event['Square'] === '40' ? 'purple' : userCard['40']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["40"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '41' ? 'purple' : userCard['41']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["41"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '42' ? 'purple' : userCard['42']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["42"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '43' ? 'purple' : userCard['43']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["43"]["value1"]}
                  </Col>
                  <Col style={{backgroundColor: event['Square'] === '44' ? 'purple' : userCard['44']['clickedStatus'] ? 'grey' : 'white'}}>
                    <b style={{fontWeight: 'bold'}}>Prompt:</b>
                    &nbsp;
                    &nbsp;
                    {userCard["44"]["value1"]}
                  </Col>
                </Row>
              </Row>
              <Row className='mb-2'>
                <Col>
                  <b style={{fontWeight: 'bold'}}>URL:</b>
                  &nbsp;
                  &nbsp;
                  <a href={event['Url']} target='_blank' rel='noreferrer'>{event['Url']}</a>
                </Col>
              </Row>
              <Row className='mb-2'>
                <Col>
                  <b style={{fontWeight: 'bold'}}>Complexity:</b>
                  <Form.Select
                    aria-label='Complexity dropdown'
                    name='base-score'
                    onChange={(e) => {calculateScore('base', e.target.value)}}
                    value={base}
                  >
                    <option value={4}>4</option>
                    <option value={8}>8</option>
                    <option value={12}>12</option>
                  </Form.Select>
                </Col>
                <Col>
                  <b style={{fontWeight: 'bold'}}>Bonus:</b>
                  <Form.Select
                    aria-label='bonus dropdown'
                    name='bonus'
                    onChange={(e) => {calculateScore('bonus', e.target.value)}}
                    value={bonus}
                  >
                    <option value={1}>No bonus</option>
                    <option value={1.25}>25% bonus</option>
                    <option value={1.5}>50% bonus</option>
                  </Form.Select>
                </Col>
                <Col>
                  <b style={{fontWeight: 'bold'}}>Multipliers:</b>
                  <Form.Select
                    aria-label='multiplier dropdown'
                    name='multiplier'
                    onChange={(e) => {calculateScore('multiplier', e.target.value)}}
                    value={multiplier}
                  >
                    <option value={1}>No multiplier</option>
                    <option value={2}>Bingo (2x)</option>
                    <option value={4}>Completed Card (4x)</option>
                  </Form.Select>
                </Col>
                <Col>
                  <b style={{fontWeight: 'bold'}}>Score:</b>
                  &nbsp;
                  &nbsp;
                  {score}
                </Col>
              </Row>
              <Row>
                <Col xs='6' md='3' xl='2'>
                  <Button onClick={() => Approve(event, finalScore)}>
                    Submit Score
                  </Button>
                </Col>
                <Col xs='6' md='3' xl='2'>
                  <Form.Label htmlFor="Final Score">Score</Form.Label>
                  <Form.Control
                    type="text"
                    id="final-score"
                    aria-describedby="final score"
                    value={finalScore}
                    onChange={(e) => setFinalScore(e.target.value)}
                  />
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        )
      })
    : <Row className='justify-content-center'>
        <Col className='d-flex justify-content-center' style={{color: 'white'}}>
          No events to display
        </Col>
      </Row>
    }
    </Accordion>
    <Modal
      show={modalShow}
      onHide={handleModalClose}
      aria-labelledby='contained-modal-success'
      centered
    >
      <Modal.Header>
        <Modal.Title>{displayMessage}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default ModTab;
