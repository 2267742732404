import { useState } from 'react';
import dayjs from 'dayjs';
import { Button, Stack, Row, Col } from 'react-bootstrap';
import CalendarWeek from './CalendarWeek.jsx';
import CalendarDayHeader from './CalendarDayHeader.jsx';
import style from './CalendarComponent.module.css';
import { CreateDaysForCurrentMonth, CreateDaysForPreviousMonth, CreateDaysForNextMonth } from './CreateDaysHelpers.jsx';

const CalendarDisplay = ({events, startDay, bdays}) => {
  //dayjs(day).isBetween(startDate, endDate, 'year', '[]')
  const [selectedDate, setSelectedDate] = useState({
    year: dayjs().format("YYYY"),
    month: dayjs().format("M")
  })
  //const TODAY = dayjs().format("YYYY-MM-DD");
  let currentMonthDays;
  let previousMonthDays;
  let nextMonthDays;

  const displayPreviousMonth = () => {
    let newMonth = selectedDate.month;
    let newYear = selectedDate.year;

    if (selectedDate.month === '1') {
      newMonth = '12';
      newYear = newYear - 1 + '';
    } else {
      newMonth = newMonth - 1 + '';
    }

    setSelectedDate({
      year : newYear,
      month: newMonth
    })
  }

  const displayNextMonth = () => {
    let newMonth = selectedDate.month;
    let newYear = selectedDate.year;

    if (selectedDate.month === '12') {
      newMonth = '1';
      newYear = +newYear + 1 + '';
    } else {
      newMonth = +newMonth + 1 + '';
    }

    setSelectedDate({
      year : newYear,
      month: newMonth
    })
  }

  currentMonthDays = CreateDaysForCurrentMonth(selectedDate.year, selectedDate.month, events, bdays);
  previousMonthDays = CreateDaysForPreviousMonth(selectedDate.year, selectedDate.month, currentMonthDays, events, startDay, bdays);
  nextMonthDays = CreateDaysForNextMonth(selectedDate.year, selectedDate.month, currentMonthDays, events, startDay, bdays);

  const days = [...previousMonthDays, ...currentMonthDays, ...nextMonthDays];

  return (
    <Stack>
      <Row className='justify-content-evenly mb-2'>
        <Col className='d-flex justify-content-center' style={{color: 'white'}}>
          <Button className='LibraryBtn' variant='outline-light' onClick={displayPreviousMonth}>
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
          </svg>
          </Button>
        </Col>
        <Col className={`d-flex justify-content-center ${style.DateDisplay}`}>
          {dayjs(new Date(selectedDate.year, selectedDate.month - 1)).format("MMMM YYYY")}
        </Col>
        <Col className='d-flex justify-content-center' style={{color: 'white'}}>
          <Button className='LibraryBtn' variant='outline-light' onClick={displayNextMonth}>
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
          </svg>
          </Button>
        </Col>
      </Row>
      <CalendarDayHeader startDay={startDay} />
      <CalendarWeek weekdays={days.slice(0, 7)} />
      <CalendarWeek weekdays={days.slice(7, 14)} />
      <CalendarWeek weekdays={days.slice(14, 21)} />
      <CalendarWeek weekdays={days.slice(21, 28)} />
      <CalendarWeek weekdays={days.slice(28, 35)} />
      <CalendarWeek weekdays={days.slice(35, 43)} />
    </Stack>
  );
};

export default CalendarDisplay;
