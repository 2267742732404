import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import CalendarDisplay from '../../components/calendar/CalendarDisplay.jsx';
import { GetPublicEvents } from '../../utilities/ApiCallers.jsx';
import LoadingAnimation from '../../components/animations/loadingAnimation.jsx';

const PubCalendar = () => {
  const [approvedEvents, setApprovedEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchApprovedEvents = async () => {
    setLoading(true);
    const response = await GetPublicEvents('/api/public/approved-events');
    setApprovedEvents(response);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await fetchApprovedEvents();
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      {loading
        ? <LoadingAnimation />
        : <CalendarDisplay events={approvedEvents} startDay={'sunday'} bdays={[]} />
      }
    </Container>
  );
};

export default PubCalendar;
