import ObjectToMap from './ObjectToMap.jsx';


let CallApi;

if (process.env.REACT_APP_ENV !== 'development') {
  CallApi = async (route, method, payload, jwt) => {
    let head;
    if (jwt !== undefined) {
      head = {
        'Content-Type': 'application/json',
        'X-Requested-With': jwt
      }
    } else {
      head = {'Content-Type': 'application/json'}
    }
    return (await fetch(`https://inu-library.com${route}`, {
      method,
      headers: head,
      body: JSON.stringify(payload)
    }))
  };
} else {
  CallApi = async (route, method, payload, jwt) => {
    let head;
    if (jwt !== undefined) {
      head = {
        'Content-Type': 'application/json',
        'X-Requested-With': jwt
      }
    } else {
      head = {'Content-Type': 'application/json'}
    }
    return (await fetch(`http://localhost${route}`, {
      method,
      headers: head,
      body: JSON.stringify(payload)
    }))
  };
};

export const GetEvents = async (endpoint, jwtToken) => {
  try {
    const response = await CallApi('/api/events/' + endpoint, 'GET', undefined, jwtToken);
    if (response.ok) {
      const decoded = await response.json();
      decoded.forEach((event) => {
        if (event.themes !== undefined) {
          const map = ObjectToMap(event.themes);
          event.themes = map;
        };
      });
      return decoded;
    } else {
      throw new Error('Error calling API');
    };
  } catch(err) {
    return [];
  };
};

export const GetPublicEvents = async (endpoint) => {
  try {
    const response = await CallApi(endpoint, 'GET');
    if (response.ok) {
      const decoded = await response.json();
      decoded.forEach((event) => {
        if (event.themes !== undefined) {
          const map = ObjectToMap(event.themes);
          event.themes = map;
        };
      });
      return decoded;
    } else {
      throw new Error('Error calling API');
    }
  } catch(err) {
    return [];
  }
};

export const GetBingoCard = async (endpoint, jwtToken) => {
  try {
    const response = await CallApi('/api/bingo/' + endpoint, 'GET', undefined, jwtToken);
    if (response.ok) {
      const decoded = await response.json();
      return JSON.parse(decoded.Card);
    } else {
      throw new Error('Error calling API');
    }
  } catch(err) {
    if (process.env.REACT_APP_ENV === 'development') {
      console.log(err);
    };
    return {};
  }
};

export const GetBingoSubmissions = async (endpoint, jwtToken) => {
  try {
    const response = await CallApi('/api/bingo/' + endpoint, 'GET', undefined, jwtToken);
    if (response.ok) {
      const decoded = await response.json();
      return decoded;
    } else {
      throw new Error('Error calling API');
    }
  } catch(err) {
    return [];
  }
};

export const GetLeaderboard = async (endpoint, jwtToken) => {
  try {
    const response = await CallApi('/api/bingo/' + endpoint, 'GET', undefined, jwtToken);
    if (response.ok) {
      const decoded = await response.json();
      decoded.forEach((record) => {
        const map = ObjectToMap(record);
        record = map;
      });
      return decoded;
    } else {
      throw new Error('Error calling API');
    }
  } catch(err) {
    return [];
  }
};

export default CallApi;
