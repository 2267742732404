import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { Row, Col, Container, Modal } from 'react-bootstrap';
import { DiscordLoginButton, TwitterLoginButton } from 'react-social-login-buttons';
import LoadingAnimation from '../../components/animations/loadingAnimation';
import style from './Login.module.css';
import useAuth from '../../utilities/AuthContext';
import TumblrLoginButton from '../../components/buttons/LoginWithTumblr.jsx';


const environment = process.env.REACT_APP_ENV !== 'development'
const DISCORDLINK = environment
  ? 'https://discord.com/api/oauth2/authorize?client_id=914033400875216979&redirect_uri=https%3A%2F%2Finu-library.com%2Flogin&response_type=code&scope=identify' // PROD
  : 'https://discord.com/api/oauth2/authorize?client_id=914033400875216979&redirect_uri=http%3A%2F%2Flocalhost%2Flogin&response_type=code&scope=identify'; // DEV
const TUMBLRLINK = environment
  ? 'https://www.tumblr.com/oauth2/authorize?state=123456789&scope=basic offline_access&response_type=code&client_id=14YJkyMRddO3X0DGLNG2JlhzF3xjLfTByA5USBLnmJ5ckae2s7&redirect_uri=https://inu-library.com/login/tumblr' // PROD
  : 'https://www.tumblr.com/oauth2/authorize?state=123456789&scope=basic write offline_access&response_type=code&client_id=14YJkyMRddO3X0DGLNG2JlhzF3xjLfTByA5USBLnmJ5ckae2s7&redirect_uri=http://localhost/login/tumblr'; // DEV
const TWITTERLINK = environment
  ? 'https://twitter.com/i/oauth2/authorize?response_type=code&client_id=ZkQ3RC1ZbnhManFvaXlLRjFzRGo6MTpjaQ&redirect_uri=https://inu-library.com/login/twitter&scope=tweet.read%20users.read%20offline.access&state=state&code_challenge=inu-library-extension-of-inu-mothership-twitter-login&code_challenge_method=plain' //PROD
  : 'https://twitter.com/i/oauth2/authorize?response_type=code&client_id=ZkQ3RC1ZbnhManFvaXlLRjFzRGo6MTpjaQ&redirect_uri=http://localhost/login/twitter&scope=tweet.read%20users.read%20offline.access&state=state&code_challenge=inu-library-extension-of-inu-mothership-twitter-login&code_challenge_method=plain'; // DEV


const Login = () => {
  const [searchParams] = useSearchParams();
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();
  const code = searchParams.get('code');
  const location = useLocation();

  const closeError = () => setError(false);

  useEffect(() => {
    if (code) {
      setSubmit(true);
      let loc = location.pathname.split('/')[2];
      if (loc === undefined) {
        loc = 'discord'
      };
      auth.signIn(
        code, loc,
        () => {
          setSubmit(false);
          navigate('/');
        },
        () => {
          setSubmit(false);
          setError(true);
        }
      );
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {!submit
        ? <Container>
            <Row>
              <Col className={`d-flex justify-content-center mx-auto ${style.Heading}`}>
                The Inuyasha Library
              </Col>
            </Row>
            <Row>
              <Col className={`d-flex justify-content-center mx-auto mt-1 ${style.Subheading}`}>
                An extension of the Inuyasha Mothership Discord Server
              </Col>
            </Row>
            <Row className='mb-5'>
              <Col className='d-flex justify-content-center mt-5'>
                <a
                  className={style.login}
                  href={DISCORDLINK}
                  rel='external'
                >
                  <DiscordLoginButton />
                </a>
              </Col>
            </Row>
            <Row className='mb-5'>
              <Col className='d-flex justify-content-center mt-2'>
                <a
                  className={style.login}
                  href={TUMBLRLINK}
                  rel='external'
                >
                  <TumblrLoginButton />
                </a>
              </Col>
            </Row>
            <Row className='mb-5'>
              <Col className='d-flex justify-content-center mt-2'>
                <a
                  className={style.login}
                  href={TWITTERLINK}
                  rel='external'
                >
                  <TwitterLoginButton />
                </a>
              </Col>
            </Row>
            <Row>
              <Col className={`d-flex justify-content-center mt-5 pt-2 ${style.Nonmember}`}>
                If you are not a member, you may apply to join the server 
                <a href='https://docs.google.com/forms/d/e/1FAIpQLSeaOjNXIPkIVHikQdlu12Rjvx2-mNKGvMccM7qYopHoDVKaLA/viewform'>
                  here
                </a>
                .
              </Col>
            </Row>
          </Container>
        : <LoadingAnimation />
      }

      <Modal show={error} onHide={closeError}>
        <Modal.Header closeButton>
          <Modal.Title>Error Logging In</Modal.Title>
        </Modal.Header>
        <Modal.Body>If the issue continues, please reach out to a mod</Modal.Body>
      </Modal>
    </>
  )
};

export default Login;
