import dayjs from 'dayjs';
import { Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import style from './CalendarComponent.module.css';

const DayLg = ({day}) => {
  const TODAY = dayjs().format("YYYY-MM-DD");
  const currentMonth = day.isCurrentMonth ? `${style.Day}` : `${style.NotCurrentMonth} ${style.Day}`;
  const currentDay = day.date === TODAY ? style.CurrentDay : '';

  return (
    <Row key={`day${day.dayOfMonth}`} className={currentMonth + ' ' + currentDay}>
      <Col className='mt-1'>
        {day.dayOfMonth}
      </Col>
      {day.birthdays.length !== 0
        ? <Col className='mt-1 me-3' xs='2'>
            <OverlayTrigger
              trigger="click"
              key={day.bday}
              placement='bottom'
              overlay={
                <Popover id={`popover-positioned-bottom`}>
                  <Popover.Header className={style.PopoverSpacing} as="h3">
                    Happy Birthday!
                  </Popover.Header>
                  <Popover.Body className={style.PopoverSpacing}>
                    {day.birthdays.map((member) => {
                      return `${member.username}\n`;
                    })}
                  </Popover.Body>
                </Popover>
              }
            >
              <button
                type='button'
                style={{background: 'none', border: 'none'}}
              >
                🥳
              </button>
            </OverlayTrigger>
          </Col>
        : null
      }
    </Row>
  );
};

export default DayLg;
