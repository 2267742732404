import { Container, Row, Col, Card, Button, ListGroup } from 'react-bootstrap';
import inu_thumb from '../../components/assets/thumbnails/inuyasha_vol_1.jpg';
import mao_thumb from '../../components/assets/thumbnails/mao_vol_1.jpg';

const environment = process.env.REACT_APP_ENV !== 'development'
const LOCATION = environment
  ? 'https://inu-library.com' // PROD
  : 'http://localhost' // DEV

const Manga = () => {
  const IY_VOL = [1, 3, 5, 7, 9, 11, 13, 15, 17] // Array.from({length: 18}, (_, i) => i + 1)
  const MAO_VOL = [1, 3, 5, 7, 9] // Array.from({length: 9}, (_, i) => i + 1)

  return (
    <Container>
      <Row>
        <Col
          style={{
            color: 'white',
            fontSize: '30px',
            fontWeight: 'bold',
            borderBottom: '1px solid white'
            }}
          >
          Official Viz Manga
        </Col>
      </Row>
      <Row>
        <Col style={{color: 'white', fontSize: '18px', marginTop: '10px', marginBottom: '10px'}}>
          {`Our member, Yukinon, has been kind enough to share her Viz account with everyone.
          To access it, navigate to Viz.com or download the app. The credentials are:`}
          <br />
          <br />
          {`Username: Yukinon_writes`}
          <br />
          {`Password: InuyashaManga#1`}
          <br />
          <br />
          {`Once logged in, proceed to "My Library" to access all purchased manga. Enjoy!`}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col
          style={{
            color: 'white',
            fontSize: '30px',
            fontWeight: 'bold',
            borderBottom: '1px solid white'
            }}
          >
          Currently Available
        </Col>
      </Row>
      <Row>
        <Col>
        <Card
          text='white'
          style={{
            width: '18rem',
            border: '1px solid rgb(75,0,130)',
            backgroundColor: 'rgb(75,0,130)',
            textAlign: 'center'
            }}
          >
          <Card.Img variant='top' src={inu_thumb} style={{width: '9rem', margin: '0 auto'}} />
          <Card.Body>
            <Card.Title style={{fontWeight: 'bold'}}>Inuyasha</Card.Title>
            <Card.Text>Downloads</Card.Text>
            <ListGroup variant="flush">
              {IY_VOL.map((vol) =>{
                return (
                  <ListGroup.Item style={{backgroundColor: 'rgb(75,0,130)'}}>
                    <a href={`${LOCATION}/api/manga/inuyasha/${vol}.zip`}>
                      <Button className='LibraryBtn' variant='outline-light'>
                        {`Volume ${vol}`}
                      </Button>
                    </a>
                    <a href={`${LOCATION}/api/manga/inuyasha/${vol + 1}.zip`}>
                      <Button className='LibraryBtn' variant='outline-light'>
                        {`Volume ${vol + 1}`}
                      </Button>
                    </a>
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </Card.Body>
        </Card>
        </Col>
        <Col>
        <Card
          text='white'
          style={{
            width: '18rem',
            border: '1px solid rgb(75,0,130)',
            backgroundColor: 'rgb(75,0,130)',
            textAlign: 'center'
            }}
          >
          <Card.Img variant='top' src={mao_thumb} style={{width: '9rem', margin: '0 auto'}} />
          <Card.Body>
            <Card.Title style={{fontWeight: 'bold'}}>Mao</Card.Title>
            <Card.Text>Downloads</Card.Text>
            <ListGroup variant="flush">
              {MAO_VOL.map((vol) =>{
                return (
                  <ListGroup.Item style={{backgroundColor: 'rgb(75,0,130)'}}>
                    <a href={`${LOCATION}/api/manga/mao/${vol}.zip`}>
                      <Button className='LibraryBtn' variant='outline-light'>
                        {`Volume ${vol}`}
                      </Button>
                    </a>
                    {vol + 1 !== 10 ?
                    <a href={`${LOCATION}/api/manga/mao/${vol + 1}.zip`}>
                      <Button className='LibraryBtn' variant='outline-light'>
                        {`Volume ${vol + 1}`}
                      </Button>
                    </a>
                    : <></>
                    }
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </Card.Body>
        </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Manga;
