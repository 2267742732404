import { createBingoObj, randomizeValues } from './BingoUtilites.jsx';
import { useBingoContext } from './BingoContext.jsx';
import RefreshButton from '../buttons/RefreshButton.jsx';

const BingoRefresh = () => {
  const state = useBingoContext();
  const { setBingoCard, noBingo, valueList, twoValues } = state;

  const refresh = (valueList, twoValues) => {
    const newList = randomizeValues(valueList, twoValues);
    const bingoObj = createBingoObj(newList, twoValues);
    setBingoCard(bingoObj);
  };
  return <RefreshButton callback={() => refresh(valueList, twoValues)} status={noBingo} text=' Shuffle' />;
};

export default BingoRefresh;
