import { Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './routes/app/Header.jsx';
import AnimatedLinks from './routes/animation/AnimatedLinks.jsx';
import NotFound from './routes/notFound/NotFound.jsx';
import Bingo from './routes/bingo/Bingo.jsx';
import Login from './routes/login/Login.jsx';
import Home from './routes/home/Home.jsx';
import Calendar from './routes/calendar/Calendar';
import { AuthProvider, RequireMember, AllowAny } from './utilities/AuthContext.jsx';
import Manga from './routes/library/Manga.jsx';
import Refresh from './routes/refresh/Refresh.jsx';
import Logout from './routes/logout/Logout.jsx';
import ProfileSettings from './routes/settings/ProfileSettings.jsx';
import PubCalendar from './routes/public/PubCalendar.jsx';
import MembersOnly from './routes/forbidden/MembersOnly.jsx';
import ChapterGenerator from './routes/chapterGenerator/ChapterGenerator.jsx';
import Inuvember from './routes/inuvember/Inuvember.jsx';
import PublicBingo from './routes/inuvember/PublicBingo.jsx';

const App = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path='/'
          element={<Header />}
        >
          <Route index
            element={<AllowAny><Home /></AllowAny>}
          />
          <Route path='login'
            element={<Login />}
          />
          <Route path='login/tumblr'
            element={<Login />}
          />
          <Route path='login/twitter'
            element={<Login />}
          />
          <Route path='logout'
            element={<Logout />}
          />
          <Route path='refresh'
            element={<Refresh />}
          />
          <Route path='membersonly'
            element={<MembersOnly />}
          />
          <Route path='public/calendar'
            element={<PubCalendar />}
          />
          <Route path='public/chapter-generator'
            element={<AllowAny><ChapterGenerator /></AllowAny>}
          />
          <Route path='public/bingo-bonanza'
            element={<AllowAny><PublicBingo /></AllowAny>}
          />
          <Route path='bingo-bonanza'
            element={<AllowAny><Inuvember /></AllowAny>}
          />
          <Route path='bingo'
            element={<RequireMember><Bingo /></RequireMember>}
          />
          <Route path='recommendations'
            element={<RequireMember><AnimatedLinks /></RequireMember>}
          />
          <Route path='calendar'
            element={<RequireMember><Calendar /></RequireMember>}
          />
          <Route path='manga'
            element={<RequireMember><Manga /></RequireMember>}
          />
          <Route path='settings/profile'
            element={<RequireMember><ProfileSettings /></RequireMember>}
          />
          <Route path='*'
            element={<NotFound />}
          />
        </Route>
      </Routes>
    </AuthProvider>
  );
};

export default App;
