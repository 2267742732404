import { Stack, Row, Col } from 'react-bootstrap';
import DaySm from './CalendarDaySm.jsx';
import DayLg from './CalendarDayLg.jsx';
import CalendarEventsLg from './CalendarEventsLg.jsx';

const CalendarWeek = ({weekdays}) => {
  // Temp setting to smaller calendar until I can fix the display
  //const windowSize = window.matchMedia('(min-width: 1001px)').matches;
  const windowSize = window.matchMedia('(min-width: 1200px)').matches;

  return (
    <>
    {weekdays.length !== 0 ? <Row className='mx-auto'>
      <Stack direction='horizontal'>
        <Row>
        {weekdays.map((day) => {
          // Setup days space
          // Compare day.date against event dates and pass event on match
          return windowSize
            ? <Col key={`day-column${day.dayOfMonth}`}>
                <DayLg key={`parent-day${day.dayOfMonth}`} day={day} />
              </Col>
            : <Col key={`day-column${day.dayOfMonth}`}>
                <DaySm key={`parent-day${day.dayOfMonth}`} day={day} />
              </Col>;
        })}
        </Row>
      </Stack>
      {windowSize
        ? <Stack
            direction='horizontal'
            style={{
              position: 'absolute',
              height: '65px',
              width: `951px`,
              marginTop: '30px'
            }}
          >
          <CalendarEventsLg weekdays={weekdays} />
        </Stack> 
      : <></>}
    </Row> : <></>}
    </>
  );
};

export default CalendarWeek;
