import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { DiscordLoginButton, TwitterLoginButton } from 'react-social-login-buttons';
import useAuth from '../../utilities/AuthContext';
import TumblrLoginButton from '../../components/buttons/LoginWithTumblr.jsx';
import style from './Logout.module.css';


const environment = process.env.REACT_APP_ENV !== 'development'
const DISCORDLINK = environment
  ? 'https://discord.com/api/oauth2/authorize?client_id=914033400875216979&redirect_uri=https%3A%2F%2Finu-library.com%2Flogin&response_type=code&scope=identify' // PROD
  : 'https://discord.com/api/oauth2/authorize?client_id=914033400875216979&redirect_uri=http%3A%2F%2Flocalhost%2Flogin&response_type=code&scope=identify'; // DEV
const TUMBLRLINK = environment
  ? 'https://www.tumblr.com/oauth2/authorize?state=123456789&scope=basic offline_access&response_type=code&client_id=14YJkyMRddO3X0DGLNG2JlhzF3xjLfTByA5USBLnmJ5ckae2s7&redirect_uri=https://inu-library.com/login/tumblr' // PROD
  : 'https://www.tumblr.com/oauth2/authorize?state=123456789&scope=basic write offline_access&response_type=code&client_id=14YJkyMRddO3X0DGLNG2JlhzF3xjLfTByA5USBLnmJ5ckae2s7&redirect_uri=http://localhost/login/tumblr'; // DEV
const TWITTERLINK = environment
  ? 'https://twitter.com/i/oauth2/authorize?response_type=code&client_id=ZkQ3RC1ZbnhManFvaXlLRjFzRGo6MTpjaQ&redirect_uri=https://inu-library.com/login/twitter&scope=tweet.read%20users.read%20offline.access&state=state&code_challenge=inu-library-extension-of-inu-mothership-twitter-login&code_challenge_method=plain' //PROD
  : 'https://twitter.com/i/oauth2/authorize?response_type=code&client_id=ZkQ3RC1ZbnhManFvaXlLRjFzRGo6MTpjaQ&redirect_uri=http://localhost/login/twitter&scope=tweet.read%20users.read%20offline.access&state=state&code_challenge=inu-library-extension-of-inu-mothership-twitter-login&code_challenge_method=plain'; // DEV


const Logout = () => {
  const auth = useAuth();
  const [logout, setLogout] = useState(false);

  useEffect(() => {
    auth.signOut(() => {
      setLogout(true);
    })
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {logout
        ? <Container>
            <Row className='justify-content-center'
                style={{color: 'white', fontWeight: 'bold', fontSize: '20px', marginTop: '30vh'}}>
                You have been successfully logged out!
            </Row>
            <Row className='mb-5'>
              <Col className='d-flex justify-content-center mt-2'>
                <a
                  className={style.logout}
                  href={DISCORDLINK}
                  rel='external'
                >
                  <DiscordLoginButton />
                </a>
              </Col>
            </Row>
            <Row className='mb-5'>
              <Col className='d-flex justify-content-center mt-2'>
                <a
                  className={style.logout}
                  href={TUMBLRLINK}
                  rel='external'
                >
                  <TumblrLoginButton />
                </a>
              </Col>
            </Row>
            <Row className='mb-5'>
              <Col className='d-flex justify-content-center mt-2'>
                <a
                  className={style.logout}
                  href={TWITTERLINK}
                  rel='external'
                >
                  <TwitterLoginButton />
                </a>
              </Col>
            </Row>
          </Container>
        : <Row>Logging out</Row>
      }
    </>
  );
};

export default Logout;
