import Jaken from '../../assets/sprites/Jaken.png';
import style from './Jaken.module.css';
import Walking from '../walking.jsx';

const JakenWalking = ({top, left}) => {
  return (
    <Walking
      container={style.walkingContainer}
      character='Jaken'
      sheet={Jaken}
      top={top}
      left={left}
      frames={4}
    />
  )
}

export default JakenWalking;
