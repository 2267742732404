import { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import BingoCard from '../../components/bingo/BingoCard.jsx';
import BingoRefresh from '../../components/bingo/BingoRefresh.jsx';
import BingoDecoration from '../../components/bingo/BingoDecoration.jsx';
import Selection from './SendSelection.jsx';
import { GetBingoCard } from '../../utilities/ApiCallers.jsx';
import useAuth from '../../utilities/AuthContext.jsx';
import { useBingoContext } from '../../components/bingo/BingoContext.jsx';
import { checkBingo } from '../../components/bingo/BingoUtilites.jsx';


const BingoTab = () => {
  const auth = useAuth();
  const user = auth.UserObj ? auth.UserObj : {token: 'asdfadsfasdf', id: '12345', username: 'user'};
  const state = useBingoContext();
  const { setBingoCard, setNoBingo, event } = state;
  const session = sessionStorage.getItem(event);

  useEffect(() => {
    const getBingoCard = async () => {
    if (session === null) {
      const response = await GetBingoCard('get-card/' + user.id, user.token);
      if (Object.keys(response).length > 0) {
        setBingoCard(response);
        setNoBingo(() => checkBingo(response));
        sessionStorage.setItem(event, JSON.stringify(response));
      };
    } else {
      const card = JSON.parse(session);
      if (card !== null) {
        setNoBingo(() => checkBingo(card));
      };
      setBingoCard(card);
    };
  };
    getBingoCard();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row>
      <Col
        xs={{ span: 12, order: 2 }}
        lg={{ span: 4, order: 1 }}
      >
        {/* Left side */}
        <BingoDecoration />
      </Col>
      <Col
        xs={{ span: 12, order: 1 }}
        lg={{ span: 8, order: 2 }}
      >
        <BingoCard />
        <Row className='justify-content-center mt-4'>
          <Col className='d-flex justify-content-center' xs='6' lg='2'>
            <Selection />
          </Col>
          <Col className='d-flex justify-content-center' xs='6' lg='2'>
            <BingoRefresh />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BingoTab;
