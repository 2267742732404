import { Row, Col } from 'react-bootstrap';
import BingoCard from '../../components/bingo/BingoCard.jsx';
import BingoRefresh from '../../components/bingo/BingoRefresh.jsx';
import { BingoSave, BingoLoad }  from '../../components/bingo/BingoSaveLoadDelete.jsx';
import BingoDecoration from '../../components/bingo/BingoDecoration.jsx';


const BingoTab = () => {
  return (
    <Row>
      <Col
        xs={{ span: 12, order: 2 }}
        lg={{ span: 4, order: 1 }}
      >
        {/* Left side */}
        <BingoDecoration />
      </Col>
      <Col
        xs={{ span: 12, order: 1 }}
        lg={{ span: 8, order: 2 }}
      >
        <BingoCard />
        <Row className='justify-content-center mt-4'>
          <Col className='d-flex justify-content-center' xs='6' lg='2'>
            <BingoSave />
          </Col>
          <Col className='d-flex justify-content-center' xs='6' lg='2'>
            <BingoLoad />
          </Col>
          <Col className='d-flex justify-content-center' xs='6' lg='2'>
            <BingoRefresh status={false} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BingoTab;
