import Animation from './animate';

const Walking = ({container, character, sheet, frames}) => {
  return (
    <div className={container}>
      <Animation image={sheet} character={`${character}-walking`} total={frames} />
    </div>
  )
}

export default Walking;
