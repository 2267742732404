import { useState } from 'react';
import { Row, Col, Button, Form, Offcanvas, Spinner, Alert, Toast, ToastContainer } from 'react-bootstrap';
import { Formik } from 'formik';
import { object, string, ref, date } from 'yup';
import dayjs from 'dayjs';
import style from './CalendarComponent.module.css';
import useAuth from '../../utilities/AuthContext.jsx';
import CallApi from '../../utilities/ApiCallers.jsx';

const schema = object().shape({
  eventName: string().required('Event must have a name'),
  pairing: string().required('Choose a pairing')
    .oneOf(['InuKag', 'SessKag', 'MirSan', 'KogKag', 'SessKagu', 'TogIza', 'Other', 'Multi-ship']),
  organizer: string().required('Organizer is required'),
  orgLink: string().required('URL required').url('Must be a valid URL'),
  startDate: date().required('Date entered not valid')
    .min(new Date(), 'Start date must be after today'),
  endDate: date().when('startDate', (startDate, schema) => {
    if (startDate !== undefined && dayjs(startDate).isValid()) {
      let date = new Date(startDate);
      date.setHours(startDate.getHours() + 1);
      return schema.notRequired()
        .min(date, 'End date must be after start date');
    } else {
      return schema;
    }
  }),
  regDate: date().notRequired()
    .max(ref('startDate'), 'Registration must be before the start date'),
  themes: string()
    .matches(/^[a-z0-9, ()]+$/i, 'Alphanumeric, space, and comma characters only')
    .notRequired(),
});

const ROUTE = '/api/events/add-event';
const METHOD = 'POST';

const SubmitTab = () => {
  const auth = useAuth();
  const user = auth.UserObj ? auth.UserObj : {user: 'user', id: '12345', token: 'adbsadfasdfaf'};
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [submit, setSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitFailure, setSubmitFailure] = useState(false);

  const submitForm = async (values) => {
    setSubmit(true);
    const themeSplit = values.themes.split(',');
    const filtered = [];
    for (let index = 0; index < themeSplit.length; index++) {
      if (themeSplit[index].trim() !== '') {
        filtered.push(themeSplit[index].trim());
      };
    };

    const themeArr = new Map();
    filtered.forEach((theme, index) => {
      if (theme.trim() !== '') {
        themeArr.set(dayjs(dayjs(values.startDate)
          .add(index, 'day')).format('YYYY-MM-DD'), theme.trim());
      };
    });
    const eventObj = {
      ...values,
      themes: Object.fromEntries(themeArr),
      userId: user.id,
      username: user.user,
      status: 'pending',
      reason: '',
      id: new Date().getTime() + ''
    };

    try {
      const response = await CallApi(ROUTE, METHOD, eventObj, user.token);
      if (response.ok) {
        setSubmitSuccess(true);
        setSubmit(false);
      };
    } catch(err) {
      setSubmitFailure(true);
      setSubmit(false);
    };
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={values => submitForm(values)}
      initialValues={{
        eventName: '',
        pairing: '',
        organizer: '',
        orgLink: '',
        startDate: '',
        endDate: '',
        regDate: '',
        themes: '',
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className='mb-4 ps-3' style={{color: 'white', fontSize: '13px'}}>
            * Required Information
          </Row>
          <Row className='mb-3'>
          <Form.Group className='' as={Col} md='6' controlId='validationEventName'>
              <Form.Label className={style.FormHeader}>Event name *</Form.Label>
              <Form.Control
                type='text'
                placeholder='Multishipper Day!'
                name='eventName'
                value={values.eventName}
                onChange={handleChange}
                isValid={touched.eventName && !errors.eventName}
                isInvalid={touched.eventName !== undefined && !!errors.eventName}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.eventName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md='6' controlId='validationPairing'>
              <Form.Label className={style.FormHeader}>Character pairing *</Form.Label>
              <Form.Select
                aria-label='Pairing select dropdown'
                name='pairing'
                value={values.pairing}
                onChange={handleChange}
                isValid={touched.pairing && !errors.pairing}
                isInvalid={touched.pairing !== undefined && !!errors.pairing}
                onBlur={handleBlur}
              >
                <option className='LibraryBtn' value=''>Select one</option>
                <option value='InuKag'>Inuyasha/Kagome</option>
                <option value='SessKag'>Sesshomaru/Kagome</option>
                <option value='MirSan'>Miroku/Sango</option>
                <option value='KogKag'>Koga/Kagome</option>
                <option value='SessKagu'>Sesshomaru/Kagura</option>
                <option value='TogIza'>Toga/Izayoi</option>
                <option value='Other'>Other (Single pairing)</option>
                <option value='Multi-ship'>Multi-ship (Two or more pairings)</option>
              </Form.Select>
              <Form.Control.Feedback type='invalid'>
                {errors.pairing}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} md='6' controlId='validationOrganizer'>
              <Form.Label className={style.FormHeader}>Organizer name/group *</Form.Label>
              <Form.Control
                type='text'
                placeholder='The Inuyasha Library'
                name='organizer'
                value={values.organizer}
                onChange={handleChange}
                isValid={touched.organizer && !errors.organizer}
                isInvalid={touched.organizer !== undefined && !!errors.organizer}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.organizer}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md='6' controlId='validationOrgLink'>
              <Form.Label className={style.FormHeader}>Organizer's link *</Form.Label>
              <Form.Control
                type='text'
                placeholder='https://inuyashalibrary.tumblr.com/'
                name='orgLink'
                value={values.orgLink}
                onChange={handleChange}
                isValid={touched.orgLink && !errors.orgLink}
                isInvalid={touched.orgLink !== undefined && !!errors.orgLink}
                onBlur={handleBlur}
              />
              <Form.Text id='orgLinkHelpBlock' style={{color: '#BDBDBD'}}>
                Make sure 'https://' is included with the URL
              </Form.Text>
              <Form.Control.Feedback type='invalid'>
                {errors.orgLink}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} md='4' controlId='validationStartDate'>
              <Form.Label className={style.FormHeader}>Start date *</Form.Label>
              <Form.Control
                type='date'
                name='startDate'
                value={values.startDate}
                onChange={handleChange}
                isValid={touched.startDate && !errors.startDate}
                isInvalid={touched.startDate !== undefined && !!errors.startDate}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.startDate}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md='4' controlId='validationEndDate'>
              <Form.Label className={style.FormHeader}>End date (Multiday events only)</Form.Label>
              <Form.Control
                type='date'
                name='endDate'
                value={values.endDate}
                onChange={handleChange}
                isValid={touched.endDate && !errors.endDate}
                isInvalid={touched.endDate !== undefined && !!errors.endDate}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.endDate}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md='4' controlId='validationRegDate'>
              <Form.Label className={style.FormHeader}>Registration/Submission Due Date</Form.Label>
              <Form.Control
                type='date'
                name='regDate'
                value={values.regDate}
                onChange={handleChange}
                isValid={touched.regDate && !errors.regDate}
                isInvalid={touched.regDate !== undefined && !!errors.regDate}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.regDate}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Col>
              <Button
                className={`${style.FormBtn} LibraryBtn`}
                variant='outline-light'
                type='button'
                onClick={handleShow}
                disabled={values.endDate === '' || !!errors.endDate || !!errors.startDate}
              >
                Add Daily Themes
              </Button>
            </Col>
            <Offcanvas show={show} onHide={handleClose} placement='start' name='start'>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Daily Themes</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                Add a theme for each day of the event by seperating each name
                with a comma (,) . Alphanumeric characters and spaces only.
                <br />
                <br />
                <Form.Group controlId='validationThemes'>
                  <Form.Control
                    as='textarea'
                    rows='3'
                    placeholder='Day 1, Day 2, Day 3, etc...'
                    name='themes'
                    value={values.themes}
                    onChange={handleChange}
                    isValid={touched.themes && !errors.themes}
                    isInvalid={touched.themes !== undefined && !!errors.themes}
                    onBlur={handleBlur}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.themes}
                  </Form.Control.Feedback>
                </Form.Group>
              </Offcanvas.Body>
            </Offcanvas>
          </Row>
          <Button className={`${style.FormBtn} LibraryBtn`} variant='outline-light' type='submit' disabled={submit}>
            {!submit
              ? <>
                  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='bi bi-send' viewBox='0 0 16 16'>
                    <path d='M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z'/>
                  </svg>
                  &nbsp;
                  Submit
                </>
              : <>
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='submit-status'
                    aria-hidden='true'
                  />
                  Submitting...
                </>
            }
          </Button>
          <ToastContainer position='top-end' className='pe-2 pt-2'>
            <Toast
              show={submitSuccess}
              onClose={() => setSubmitSuccess(false)}
              variant='success'
              delay='5000'
              autohide
            >
              <Toast.Header>
                <strong className="me-auto">Inuyasha Library</strong>
              </Toast.Header>
              <Toast.Body>
                <Alert variant='success'>Event successfully submitted!</Alert>
              </Toast.Body>
            </Toast>
            <Toast
              show={submitFailure}
              onClose={() => setSubmitFailure(false)}
              variant='danger'
              delay='5000'
              autohide
            >
              <Toast.Header>
                <strong className="me-auto">Inuyasha Library</strong>
              </Toast.Header>
              <Toast.Body>
                <Alert variant='danger'>Error submitting event</Alert>
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </Form>
      )}
    </Formik>
  );
};

export default SubmitTab;
