import { OverlayTrigger, Popover, Row, Col } from 'react-bootstrap';
import style from './Button.module.css';

const EventButton = ({color, day, event, eventWidth, spacer, wrap}) => {
  const dailyThemes = event.themes !== undefined && event.themes.size !== 0 ? true : false;
  const keys = [];
  if (dailyThemes) {
    for (const key of event.themes.keys()) {
      keys.push(key);
    }
  }

  return (
    <OverlayTrigger
      trigger="click"
      key={day + event.eventName}
      placement='bottom'
      overlay={
        <Popover id={`popover-positioned-bottom`} style={{maxWidth: '450px'}}>
          <Popover.Header className={style.PopoverSpacing} as="h3">
            {event.eventName}
            {event.regDate === day ? '\nLAST DAY TO REGISTER/SUBMIT!!!' : ''}
          </Popover.Header>
          <Popover.Body className={style.PopoverSpacing}>
            {`Pairing: ${event.pairing}\n`}
            {`Organizer: ${event.organizer}\n`}
            <a href={event.orgLink} target='_blank' rel='noreferrer'>Contact</a>
            {dailyThemes && event.regDate !== day
              ? <>
                <Row className='mt-2'>
                  <Popover.Header>Daily Themes:</Popover.Header>
                </Row>
                <Row xs='auto'>
                  {keys.map((key, index) => {
                    // Alternative, display date: {key.slice(5)}
                    return (
                      <Col key={index + key}>
                        <span style={{fontWeight: 'bold'}}>Day {`${index + 1}`}</span>
                        : {event.themes.get(key)}
                      </Col>
                    );
                  })}
                </Row></>
              : null
            }
          </Popover.Body>
        </Popover>
      }
    >
      <button
        className={wrap ? [style.EventButton, style[color], style.WrapEvent].join(' ') : [style.EventButton, style[color]].join(' ')}
        type='button'
        style={{width: `${eventWidth}`, zIndex: spacer ? '-1' : ''}}
      >
        {event.eventName}
      </button>
    </OverlayTrigger>
  );
};

export default EventButton;
