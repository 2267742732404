import { Row, Col, Container } from 'react-bootstrap';
import BingoCard from '../../components/bingo/BingoCard.jsx';
import BingoRefresh from '../../components/bingo/BingoRefresh.jsx';
import { BingoContextProvider } from '../../components/bingo/BingoContext.jsx';


const nameList = ["Inuyasha", "Kagome", "Miroku", "Sango", "Shippo", "Kaede", "Sesshomaru",
  "Kagura", "Kikyo", "Jaken", "Koga", "Naraku", "Ayame", "Yura", "Hojo", "Izayoi",
  "InuKimi", "Toga", "Totosai", "Myoga", "Kirara"
];
const TWOVALUES = 'true';


const Bingo = () => {
  return (
    <Container>
      <BingoContextProvider valueList={nameList} twoValues={TWOVALUES} event='bingo'>
        <BingoCard />
        <Row className='justify-content-center mt-4'>
          <Col className='d-flex justify-content-center' xs='6' lg='2'>
            <BingoRefresh />
          </Col>
        </Row>
      </BingoContextProvider>
    </Container>
  );
};

export default Bingo;
