import Sesshomaru from '../../assets/sprites/Sesshomaru.png';
import style from './Sesshomaru.module.css';
import Walking from '../walking.jsx';

const SesshomaruWalking = ({top, left}) => {
  return (
    <Walking
      container={style.walkingContainer}
      character='Sesshomaru'
      sheet={Sesshomaru}
      top={top}
      left={left}
      frames={4}
    />
  )
}

export default SesshomaruWalking;
