import { Navbar, NavDropdown, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useAuth from '../../utilities/AuthContext.jsx';
import inu from '../../components/assets/thumbnails/inu-scratch.png';


const AppNavbar = () => {
  const auth = useAuth();
  const user = auth.UserObj ? auth.UserObj : false;

  if (user.active) {
    return (
      <Navbar expand='md' variant='dark'>
        <Container>
          <Navbar.Brand as={Link} to='/'>
            <img
              src={inu}
              width='75'
              height='75'
              className='d-inline-block align-top'
              alt='Inu-library logo'
            />
            Inuyasha Library
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='me-auto'>
              <Nav.Link as={Link} to='/'>Home</Nav.Link>
              <Nav.Link as={Link} to='recommendations'>Fic Recs</Nav.Link>
              <Nav.Link as={Link} to='bingo'>Bingo</Nav.Link>
              <Nav.Link as={Link} to='calendar'>Calendar</Nav.Link>
              <Nav.Link as={Link} to='manga'>Manga</Nav.Link>
              <Nav.Link as={Link} to='public/chapter-generator'>Chapter Generator</Nav.Link>
              <Nav.Link as={Link} to='bingo-bonanza'>Bingo Bonanza</Nav.Link>
            </Nav>
            <Nav className='justify-content-end'>
              <NavDropdown title={`Hello ${user.user}!`} id='navbarScrollingDropdown'>
                <NavDropdown.Item className='LibraryBtn' as={Link} to={`/settings/profile`}>
                  Settings
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item className='LibraryBtn' as={Link} to='/logout'>
                  Sign Out
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  } else if (user.active === false) {
    return (
      <Navbar expand='md' variant='dark'>
      <Container>
        <Navbar.Brand as={Link} to='/'>
          <img
            src={inu}
            width='75'
            height='75'
            className='d-inline-block align-top'
            alt='Inu-library logo'
          />
          Inuyasha Library
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='me-auto'>
            <Nav.Link as={Link} to='/'>Home</Nav.Link>
            <Nav.Link as={Link} to='public/calendar'>Calendar</Nav.Link>
            <Nav.Link as={Link} to='public/chapter-generator'>Chapter Generator</Nav.Link>
            <Nav.Link as={Link} to='bingo-bonanza'>Bingo Bonanza</Nav.Link>
          </Nav>
          <Nav className='justify-content-end'>
            <NavDropdown title={`Hello ${user.user}!`} id='navbarScrollingDropdown'>
              <NavDropdown.Item className='LibraryBtn' as={Link} to='/logout'>
                Sign Out
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    );
  } else {
    return (
      <Navbar expand='md' variant='dark'>
      <Container>
        <Navbar.Brand as={Link} to='/'>
          <img
            src={inu}
            width='75'
            height='75'
            className='d-inline-block align-top'
            alt='Inu-library logo'
          />
          Inuyasha Library
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='me-auto'>
            <Nav.Link as={Link} to='/'>Home</Nav.Link>
            <Nav.Link as={Link} to='public/calendar'>Calendar</Nav.Link>
            <Nav.Link as={Link} to='public/chapter-generator'>Chapter Generator</Nav.Link>
            <Nav.Link as={Link} to='public/bingo-bonanza'>Fairy Tale Bingo</Nav.Link>
          </Nav>
          <Nav className='justify-content-end'>
            <Nav.Link as={Link} to='login'>Log in</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    );
  };
};

export default AppNavbar;
