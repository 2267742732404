import { Row, Col, Container } from 'react-bootstrap';
import KagomeWalking from '../../components/animations/kagome/KagomeWalking.jsx';
import InuyashaWalking from '../../components/animations/inuyasha/InuyashaWalking.jsx';
import SangoWalking from '../../components/animations/sango/SangoWalking.jsx';
import MirokuWalking from '../../components/animations/miroku/MirokuWalking.jsx';
import ShippoWalking from '../../components/animations/shippo/ShippoWalking.jsx';
import KaguraWalking from '../../components/animations/kagura/KaguraWalking.jsx';
import KikyoWalking from '../../components/animations/kikyo/KikyoWalking.jsx';
import KogaWalking from '../../components/animations/koga/KogaWalking.jsx';
import SesshomaruWalking from '../../components/animations/sesshomaru/SesshomaruWalking.jsx';
import JakenWalking from '../../components/animations/jaken/JakenWalking.jsx';
import NarakuWalking from '../../components/animations/naraku/NarakuWalking.jsx';
import YuraWalking from '../../components/animations/yura/YuraWalking.jsx';
import KannaWalking from '../../components/animations/kanna/KannaWalking.jsx';
import KaedeWalking from '../../components/animations/kaede/KaedeWalking.jsx';
import KohakuWalking from '../../components/animations/kohaku/KohakuWalking.jsx';

const INU_LINK = 'https://archiveofourown.org/works/22625311';
const KAG_LINK = 'https://archiveofourown.org/works/34854388';
const SHIP_LINK ='https://archiveofourown.org/works/27709327/chapters/67816573';
const SAN_LINK = 'https://archiveofourown.org/works/28245024/chapters/69214122';
const MIR_LINK = 'https://archiveofourown.org/works/28938255/chapters/71004051';
const SESS_LINK = 'https://archiveofourown.org/works/35172289/chapters/87634399';
const KAEDE_LINK = 'https://archiveofourown.org/works/30290646/chapters/74657625';
const JAK_LINK = 'https://archiveofourown.org/works/26369659';
const KIK_LINK = 'https://archiveofourown.org/works/36901513/chapters/92064538';
const KOG_LINK = 'https://archiveofourown.org/works/29183682';
const NAR_LINK = 'https://archiveofourown.org/works/32017099';
const KAGU_LINK = 'https://archiveofourown.org/works/29366736';
const KANNA_LINK = 'https://www.fanfiction.net/s/5539774/1/The-Flower-Girl';
const YURA_LINK = 'https://archiveofourown.org/works/31651733/chapters/78331553';
const KOH_LINK = 'https://archiveofourown.org/works/36541072';

const AnimatedLinks = () => {
  return (
    <Container>
      <Row style={{color: 'white', marginTop: '2vh'}}>Updated on 2022/04/01</Row>
      <Row style={{marginBottom: '18vh', marginTop: '8vh'}}>
        <Col className='d-flex justify-content-center'>
          <a href={MIR_LINK} target='_blank' rel='noreferrer'>
            <MirokuWalking />
          </a>
        </Col>
        <Col className='d-flex justify-content-center'>
          <a href={SAN_LINK} target='_blank' rel='noreferrer'>
            <SangoWalking />
          </a>
        </Col>
        <Col className='d-flex justify-content-center pt-3'>
          <a href={SHIP_LINK} target='_blank' rel='noreferrer'>
            <ShippoWalking />
          </a>
        </Col>
        <Col className='d-flex justify-content-center'>
          <a href={KAG_LINK} target='_blank' rel='noreferrer'>
            <KagomeWalking />
          </a>
        </Col>
        <Col className='d-flex justify-content-center'>
          <a href={INU_LINK} target='_blank' rel='noreferrer'>
            <InuyashaWalking />
          </a>
        </Col>
      </Row>
      <Row style={{marginBottom: '20vh'}}>
      <Col className='d-flex justify-content-center'>
          <a href={KOG_LINK} target='_blank' rel='noreferrer'>
            <KogaWalking />
          </a>
        </Col>
        <Col className='d-flex justify-content-center'>
          <a href={KIK_LINK} target='_blank' rel='noreferrer'>
            <KikyoWalking />
          </a>
        </Col>
        <Col className='d-flex justify-content-center pt-1'>
          <a href={JAK_LINK} target='_blank' rel='noreferrer'>
            <JakenWalking />
          </a>
        </Col>
        <Col className='d-flex justify-content-center'>
          <a href={KAEDE_LINK} target='_blank' rel='noreferrer'>
            <KaedeWalking />
          </a>
        </Col>
        <Col className='d-flex justify-content-center'>
          <a href={SESS_LINK} target='_blank' rel='noreferrer'>
            <SesshomaruWalking />
          </a>
        </Col>
      </Row>
      <Row>
      <Col className='d-flex justify-content-center'>
          <a href={KOH_LINK} target='_blank' rel='noreferrer'>
            <KohakuWalking />
          </a>
        </Col>
        <Col className='d-flex justify-content-center'>
          <a href={YURA_LINK} target='_blank' rel='noreferrer'>
            <YuraWalking />
          </a>
        </Col>
        <Col className='d-flex justify-content-center'>
          <a href={KANNA_LINK} target='_blank' rel='noreferrer'>
            <KannaWalking />
          </a>
        </Col>
        <Col className='d-flex justify-content-center'>
          <a href={KAGU_LINK} target='_blank' rel='noreferrer'>
            <KaguraWalking />
          </a>
        </Col>
        <Col className='d-flex justify-content-center'>
          <a href={NAR_LINK} target='_blank' rel='noreferrer'>
            <NarakuWalking />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default AnimatedLinks;
