import Shippo from '../../assets/sprites/Shippo.png';
import style from './Shippo.module.css';
import Walking from '../walking.jsx';

const ShippoWalking = ({top, left}) => {
  return (
    <Walking
      container={style.walkingContainer}
      character='Shippo'
      sheet={Shippo}
      top={top}
      left={left}
      frames={4}
    />
  )
}

export default ShippoWalking;
