import Kaede from '../../assets/sprites/Kaede.png';
import style from './Kaede.module.css';
import Walking from '../walking.jsx';

const KaedeWalking = ({top, left}) => {
  return (
    <Walking
      container={style.walkingContainer}
      character='Kaede'
      sheet={Kaede}
      top={top}
      left={left}
      frames={4}
    />
  )
}

export default KaedeWalking;
