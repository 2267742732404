import { useState } from 'react';
import { Accordion, Badge, Button, Col, Row, Modal } from 'react-bootstrap';
import style from './CalendarComponent.module.css';
import useAuth from '../../utilities/AuthContext.jsx';
import CallApi from '../../utilities/ApiCallers.jsx';

const ROUTE = '/api/events/update-event';
const METHOD = 'PUT';

const EditTab = ({events}) => {
  const auth = useAuth();
  const user = auth.UserObj ? auth.UserObj : {user: 'user', id: '12345', token: 'adbsadfasdfaf'};
  const [modalShow, setModalShow] = useState(false);
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);
  const [displayMessage, setDisplayMessage] = useState('');

  const Edit = async (event) => {
    const temp = event;
    try {
      const response = await CallApi(ROUTE, METHOD, event, user.token);
      if (response.ok) {
        setDisplayMessage('Submission successful!');
        handleModalShow();
      }
    } catch(err) {
      event.status = temp;
      setDisplayMessage('Oops! Something went wrong! Please reach out to a mod if the problem persists.');
      handleModalShow();
    }
  }

  return (
    <>
    <Accordion>
      {events.length > 0
      ? events.map((event, index) => {
        let status = '';
        switch(event.status) {
          case 'approved':
            status = 'success';
            break;
          case 'pending':
            status = 'warning';
            break;
          case 'declined':
            status = 'danger';
            break;
          default:
            status = 'secondary';
        };

        return (
          <Accordion.Item key={event.eventName + index} eventKey={index}>
            <Accordion.Header>
              <Row>
                <Col xs='8'>
                  <Badge className={style[event.pairing]} style={{fontSize: '15px'}}>{event.eventName}</Badge>
                </Col>
                <Col xs='4'>
                  <Badge pill bg={status}>{event.status[0].toUpperCase() + event.status.substr(1)}</Badge>
                </Col>
              </Row>
            </Accordion.Header>
            <Accordion.Body>
              <Row className='mb-2'>
                <Col>{`Submitted by: ${event.username}`}</Col>
                <Col>{`Organizer: ${event.organizer}`}</Col>
                <Col>{`Blog: ${event.orgLink}`}</Col>
              </Row>
              <Row className='mb-2'>
                <Col>{`Start Date: ${event.startDate}`}</Col>
                <Col>{`End Date: ${event.endDate}`}</Col>
                <Col>{`Registration Date: ${event.regDate}`}</Col>
                <Col>{`Status: ${event.status}`}</Col>
              </Row>
              {event.status === 'pending'
              ? <Row className='justify-content-end'>
                  <Col xs='3' md='1'>
                    <Button onClick={() => Edit(event)} disabled>
                      Edit
                    </Button>
                  </Col>
                </Row>
              : <></>
              }
            </Accordion.Body>
          </Accordion.Item>
        )
      })
    : <Row className='justify-content-center'>
        <Col className='d-flex justify-content-center' style={{color: 'white'}}>
          No events to display
        </Col>
      </Row>
    }
    </Accordion>
    <Modal
      show={modalShow}
      onHide={handleModalClose}
      aria-labelledby='contained-modal-success'
      centered
    >
      <Modal.Header>
        <Modal.Title>{displayMessage}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default EditTab;
