import { Image, Row, Col, Container } from 'react-bootstrap';
import style from './Home.module.css';
import Flower from '../../components/assets/images/sessflower-white-small.png';
import HomeCarousel from '../../components/carousel/HomeCarousel.jsx';

const Home = () => {
  return (
    <Container>
      <Row>
        <h6 className={`${style.Welcome} d-flex justify-content-center`}>
          Welcome to the Inuyasha Library!
        </h6>
      </Row>
      <Row className='mt-2 justify-content-center'>
        <HomeCarousel />
      </Row>
      <Row className='justify-content-end'>
        <Col className='mt-5' xs='4' md='2' xl='1'>
          <Image className={`${style.Flower}`} src={Flower} />
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
