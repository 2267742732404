import { Outlet } from 'react-router-dom';
import AppNavbar from '../../components/navbar/Navbar.jsx';

const Header = () => {
  return (
    <div className="App">
      <AppNavbar />
      <Outlet />
    </div>
  );
};

export default Header;
